import React, { useState } from "react";
import { Checkbox, Input } from "../../../../../../../shared/react-hook-form";
import { selectError } from "../../../../../../../shared/form";
import {
  emptyResource,
  errorResource,
  firebaseReference,
  isBlank,
  isEmail,
  loadedResource,
  loadingResource,
  phone,
  requiredNotBlank,
} from "@sempra-event-registration/common";
import { Message } from "semantic-ui-react";
import { useFormContext } from "react-hook-form";
import classes from "./RegistrationFormFields.module.css";
import RegistrationSubgroup from "./component/RegistrationSubgroup/RegistrationSubgroup";
import RegistrationGroup from "./component/RegistrationGroup/RegistrationGroup";
import RegistrationEmail from "./component/RegistrationEmail/RegisrationEmail";
import RegistrationResource from "./component/RegistrationResource/RegistrationResource";
import RegiststrationMeals from "./component/RegistrationMeals/RegistrationMeals";
import RegistrationWorkshops from "./component/RegistrationWorkshops/RegistrationWorkshops";
import { emailInAudience } from "../../support/emailInAudience";
import { reservationStatuses } from "@sempra-event-registration/common/src/event";

const findUserById = firebaseReference
  .functions()
  .httpsCallable("findUserById");

function findInternalResourceById(id) {
  return findUserById({ id }).then((response) => response.data);
}

function checkAvailability(groups) {
  if (groups && Object.keys(groups).length === 0) return false;
  const availableGroup = groups.find((group) => {
    if (!group.subgroups) return true;
    return group.subgroups.find(
      (subgroup) => subgroup.registrationCount < subgroup.capacity
    );
  });
  return availableGroup && Object.keys(availableGroup || {}).length !== 0;
}

function alreadyInvitedOrRegistered(status, registrations) {
  return (value) => {
    if (isBlank(value) || !isEmail(value)) {
      return;
    }
    const existingRegistration = registrations.find(
      (x) => value === x.internalResource?.email || value === x.email
    );
    // If there exists a registrant by the same email that has the same registration status
    // OR is being invited when already registered, report an error
    if (
      existingRegistration != null &&
      (existingRegistration.status === status ||
        (existingRegistration.status === "invited" && status === "invited") ||
        (status === "invited" &&
          reservationStatuses.includes(existingRegistration.status)))
    ) {
      const { firstName, lastName } = existingRegistration;
      const name =
        !isBlank(firstName) && !isBlank(lastName)
          ? [firstName, lastName].join(" ")
          : existingRegistration.internalResource?.email ||
            existingRegistration.email;
      return {
        invited: `${name} has already been invited`,
        registered: `${name} is already registered`,
        waiting: `${name} is already on the waitlist`,
        "invited-from-waitlist": `${name} is already on the waitlist`,
      }[existingRegistration.status];
    }
  };
}

const yesNoQuestions = [
  {
    name: "firstEvent",
    label: "First event",
    enabled: (event) => event.askIfFirstEvent,
  },
  {
    name: "representedByUnion",
    label: "Represented by a union",
    enabled: (event) => event.askIfRepresentedByUnion,
  },
  {
    name: "acceptReleaseWaiver",
    label: "Accepts release waiver",
    enabled: (event) => event.askForReleaseWaiver,
  },
];

function RegistrationFormFields({
  event,
  registrations,
  registration,
  disabled,
  internalResourceSearchEnabled,
  className,
  ...rest
}) {
  const { control, errors, setValue, reset, trigger } = useFormContext();

  const invitation = registration.status === "invited";
  const initialInternalResource = registration.internalResource;
  const { groups = [], meals = [], workshops = [] } = event;
  // Setup async loaded internal resource details
  const [internalResource, setInternalResource] = useState(
    initialInternalResource != null
      ? loadedResource(initialInternalResource)
      : emptyResource()
  );

  const questions = yesNoQuestions.filter(
    (x) => x.enabled == null || x.enabled(event)
  );

  async function fillForm(internalResource) {
    setValue("firstName", internalResource.firstName);
    setValue("lastName", internalResource.lastName);
    setValue("phone", internalResource.phone);
    setValue("title", internalResource.title);
    setValue("companyName", internalResource.companyName);
    await trigger(["firstName", "lastName", "phone", "title", "companyName"]);
  }

  function fieldDisabled(valuePresent) {
    return (
      disabled ||
      internalResource.status === "loading" ||
      (internalResource.status === "loaded" &&
        internalResource.value != null &&
        valuePresent())
    );
  }

  const readonlyFields = {
    emailOrResource: disabled || !isBlank(registration.email),
    firstName: fieldDisabled(() => !isBlank(internalResource.value?.firstName)),
    lastName: fieldDisabled(() => !isBlank(internalResource.value?.lastName)),
    phone: fieldDisabled(() => !isBlank(internalResource.value?.phone)),
    title: fieldDisabled(() => !isBlank(internalResource.value?.title)),
    companyName: fieldDisabled(
      () => !isBlank(internalResource.value?.companyName)
    ),
  };

  const emailInAudienceValidator = emailInAudience(event.audience);
  const emailAvailabilityValidator = readonlyFields.emailOrResource
    ? () => {}
    : alreadyInvitedOrRegistered(registration.status, registrations);

  return (
    <div className={`${className || ""} ${classes.container}`} {...rest}>
      {internalResourceSearchEnabled ? (
        <RegistrationResource
          {...{
            control,
            disabled: readonlyFields.emailOrResource,
            errors,
            loading: internalResource.status === "loading",
            invitation,
            emailAvailabilityValidator,
            emailInAudienceValidator,
            onSearchChange: (search) => {
              // Clear form if it was auto-filled with an internal resources value
              if (internalResource.value != null) {
                setInternalResource(emptyResource());
                reset({ emailOrResource: { email: search } });
              }
            },
            onChange: async (value) => {
              setValue("emailOrResource", value, {
                shouldDirty: true,
                shouldValidate: true,
              });

              // Do not autofill if the field is in error
              // i.e. if the selected resource is already invited/registered
              const valid = await trigger("emailOrResource");
              if (!valid) {
                return;
              }

              setInternalResource(loadingResource());
              findInternalResourceById(value.id)
                .then((resource) => {
                  setValue("emailOrResource", resource);
                  setInternalResource(loadedResource(resource));
                  return fillForm(resource);
                })
                .catch((error) => {
                  console.debug(
                    `Failed to find internal resource "${value.id}"`,
                    error
                  );
                  setInternalResource(errorResource(error));
                });
            },
          }}
        />
      ) : (
        <RegistrationEmail
          {...{
            control,
            disabled: readonlyFields.emailOrResource,
            errors,
            emailAvailabilityValidator,
            emailInAudienceValidator,
          }}
        />
      )}
      <div className={classes.twoColumnGrid}>
        <Input
          control={control}
          disabled={readonlyFields.firstName}
          name="firstName"
          label="First Name"
          type="text"
          error={selectError(() => errors.firstName)}
          rules={
            invitation
              ? {}
              : {
                  validate: requiredNotBlank("Required"),
                }
          }
        />
        <Input
          control={control}
          disabled={readonlyFields.lastName}
          name="lastName"
          label="Last Name"
          type="text"
          error={selectError(() => errors.lastName)}
          rules={
            invitation
              ? {}
              : {
                  validate: requiredNotBlank("Required"),
                }
          }
        />
      </div>
      <div className={classes.twoColumnGrid}>
        <Input
          control={control}
          disabled={readonlyFields.title}
          name="title"
          label="Job Title"
          type="text"
          error={selectError(() => errors.title)}
        />
        <Input
          control={control}
          disabled={readonlyFields.companyName}
          name="companyName"
          label="Company"
          type="text"
          error={selectError(() => errors.companyName)}
        />
      </div>
      <Input
        control={control}
        disabled={readonlyFields.phone}
        error={selectError(() => errors.phone)}
        name="phone"
        label="Phone"
        type="phone" // TODO: Changing this to 'text' fixes the disabled text issue, but introduces other errors
        rules={{
          validate: phone(),
        }}
      />
      {/* the below fields are only for registrants */}
      {invitation || (
        <>
          {groups.length > 0 && (
            <div>
              <Message
                visible={!checkAvailability(groups)}
                error
                header="Action Required"
                content="All groups are at full capacity"
              />
              <div className={classes.twoColumnGrid}>
                <RegistrationGroup {...{ disabled, event, registration }} />
                <RegistrationSubgroup {...{ disabled, event, registration }} />
              </div>
            </div>
          )}

          {meals.length > 0 && (
            <div className={classes.section}>
              <label>Meals</label>
              <RegiststrationMeals {...{ disabled, event }} />
            </div>
          )}

          {workshops.length > 0 && (
            <div className={classes.section}>
              <label>Workshops</label>
              <RegistrationWorkshops {...{ control, disabled, event }} />
            </div>
          )}

          {questions.length > 0 && (
            <div className={classes.section}>
              <label>Additional Questions</label>
              {questions.map((question) => (
                <Checkbox
                  control={control}
                  key={question.name}
                  id={question.name}
                  name={question.name}
                  label={question.label}
                  disabled={disabled}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RegistrationFormFields;
