import React from "react";
import classes from "./NameBadgeReport.module.css";
import { isBlank } from "@sempra-event-registration/common";

function NameBadge({ badge }) {
  const { firstName, lastName, title, department } = badge;
  return (
    <div className={classes.nameBadge}>
      <div className={classes.content}>
        <div className={classes.name}>
          {firstName} {lastName}
        </div>
        {!isBlank(title) && <div className={classes.jobTitle}>{title}</div>}
        {!isBlank(department) && (
          <div className={classes.department}>{department}</div>
        )}
      </div>
    </div>
  );
}

function NameBadgeReport({ badges }) {
  return (
    <div className={classes.nameBadgeReport}>
      {badges.map((badge, index) => (
        <NameBadge key={index} badge={badge} />
      ))}
    </div>
  );
}

export default NameBadgeReport;
