import React, { createRef, useContext, useState } from "react";
import {
  absenceStatuses,
  registeredOrWaiting,
  compose,
  Error,
  eventToEventTimeStatus,
  firebaseReference,
  useEventRegistrantSaver,
  useLoadedUser,
} from "@sempra-event-registration/common";
import {
  formValuesToRegistration,
  registrationToFormDefaultValues,
} from "./support/registrationForms";
import chunk from "lodash.chunk";
import AddRegistrationButton from "./component/AddRegistrationButton/AddRegistrationButton";
import RegistrationTable from "./component/RegistrationTable/RegistrationTable";
import RegistrationModal from "./component/RegistrationModal/RegistrationModal";
import RemoveRegistrationModal from "./component/RemoveRegistrationModal/RemoveRegistrationModal";
import InviteesModal from "./component/InviteesModal/InviteesModal";
import { inviteeValidator } from "./support/inviteeForms";
import { EventContext } from "../../context/EventContext";
import Loader from "../../../../../shared/ui/component/Loader/Loader";
import { useEventRegistrations } from "../../hook/useEventRegistrations";
import { Button, Dropdown, Icon, Label, Segment } from "semantic-ui-react";
import classes from "./EventRegistrationPage.module.css";
import { IconButton, PageHeader } from "../../../../../shared/ui";
import { usePrintContext } from "../../../../../shared/print";
import NameBadgeReport from "./component/NameBadge/NameBadgeReport";
import toastNotification from "../../../../../shared/toastNotification/toastNotification";
import { viewDataOption } from "../../../../../shared/development";

function withResources(Component) {
  return (props) => {
    const user = useLoadedUser();
    const eventResource = useContext(EventContext);
    const registrationsResource = useEventRegistrations(
      eventResource.value?.id
    );
    const resources = compose(eventResource, registrationsResource);
    if (resources.status === "loading" || resources.status === "empty") {
      return <Loader />;
    }
    if (resources.status === "error") {
      return <Error message={eventResource.error} />;
    }

    const [event, registrations] = resources.value;
    return <Component {...{ user, event, registrations }} {...props} />;
  };
}

function registrationToNameBadge(registration) {
  const {
    firstName,
    lastName,
    title,
    internalResource: { department } = {},
  } = registration;

  return {
    firstName,
    lastName,
    title,
    department,
  };
}

function EventRegistrationPage({ user, event, registrations }) {
  const [saveRegistrant] = useEventRegistrantSaver(user);
  const [registration, setRegistration] = useState();
  const [registrationToRemove, setRegistrationToRemove] = useState();
  const [inviteesModalOpen, setInviteesModalOpen] = useState();
  const [selecting, setSelecting] = useState(false);
  const [selectedRegistrations, setSelectedRegistrations] = useState(
    registrations.filter((x) => x.status === "registered")
  );
  const [, setPrintContent] = usePrintContext();

  const pageRef = createRef();

  // Readonly mode
  const disabled =
    ["complete", "cancelled"].includes(eventToEventTimeStatus(event)) ||
    !(event.createdBy === user.id || user.roles.includes("super_user"));

  const registrationsMenuOptions = [
    viewDataOption(`events/${event.id}/registrants`),
    {
      key: "print-name-badges",
      text: "Print name badges",
      onClick: () => {
        setSelecting(true);
      },
      enabled: () => registrations.length > 0,
    },
  ]

    .filter((x) => x.enabled == null || x.enabled())
    .map(({ enabled, ...rest }) => rest);

  function registrationMenuOptions(registration) {
    return [
      viewDataOption(`events/${event.id}/registrants/${registration.id}`),
      {
        key: "edit",
        text: absenceStatuses.includes(registration.status) ? "View" : "Edit",
        onClick: () => {
          setRegistration(registration);
        },
      },
      {
        key: "register",
        text: "Register",
        onClick: () => {
          setRegistration({
            ...registration,
            previousStatus: registration.status,
            status: "registered",
          });
        },
        enabled: (registration) =>
          !disabled &&
          !event.full &&
          !registeredOrWaiting.includes(registration.status),
      },
      {
        key: "remove",
        text: "Remove",
        onClick: () => {
          setRegistrationToRemove(registration);
        },
        enabled: (registration) =>
          !disabled && !absenceStatuses.includes(registration.status),
      },
    ]
      .filter((x) => x.enabled == null || x.enabled(registration))
      .map(({ enabled, ...rest }) => rest);
  }

  const options = [
    {
      key: "registrant",
      text: "Registrant",
      onClick: () => {
        setRegistration({
          status: "registered",
        });
      },
    },
    {
      key: "invitee",
      text: "Invitee",
      onClick: () => {
        setRegistration({
          status: "invited",
        });
      },
    },
    {
      key: "invitees",
      text: "Invitees",
      onClick: () => {
        setInviteesModalOpen(true);
      },
    },
  ];

  async function onRegistrationFormModalAccept(values, { setSubmitting }) {
    const registrationToSave = formValuesToRegistration(values, event);

    // The add registrant modal allows you to add someone as a registrant even if they are already added as an invitee
    // This is for ease of use but complicates our process as we need to make sure we do not add a duplicate registration
    const existingRegistration = registrations.find(
      (x) =>
        x.email === registrationToSave.internalResource?.email ||
        x.email === registrationToSave.email
    );

    setSubmitting(true);
    saveRegistrant(event, {
      ...registrationToSave,
      id: existingRegistration?.id || registration.id,
      status: registration.status,
    })
      .then(() => {
        toastNotification({
          title: "SUCCESFULLY ADDED",
          message: `Event: ${event.name}. For: ${registrationToSave.firstName} ${registrationToSave.lastName}`,
        });
        // TODO then case called on HTTP 500...
        console.info(`Saved registration`, registrationToSave);
        setSubmitting(false);
        setRegistration();
      })
      .catch((error) => {
        toastNotification({
          title: "ERROR WHILE ADDING",
          message: `Event: ${event.name}. For: ${registrationToSave.firstName} ${registrationToSave.lastName}`,
          type: "danger",
        });
        console.error(`Failed to save registration`, error);
        setSubmitting(false);
      });
  }

  function onRemoveRegistrationModalAccept(values, { setSubmitting }) {
    setSubmitting(true);
    saveRegistrant(event, {
      ...registrationToRemove,
      status: "removed",
    })
      .then(() => {
        toastNotification({
          title: "SUCCESFULLY REMOVED",
          message: `Event: ${event.name}. Registrant: ${registrationToRemove.firstName} ${registrationToRemove.lastName}`,
        });
        // TODO then case called on HTTP 500...
        console.info(`Saved registration`, registrationToRemove);
        setSubmitting(false);
        setRegistrationToRemove();
      })
      .catch((error) => {
        toastNotification({
          title: "ERROR WHILE REMOVING",
          message: `Event: ${event.name}. Registrant: ${registrationToRemove.firstName} ${registrationToRemove.lastName}`,
          type: "danger",
        });
        console.error(`Failed to save registration`, error);
        setSubmitting(false);
      });
  }

  async function onInviteesFormModalAccept(invitees, { setSubmitting }) {
    setSubmitting(true);
    // Should this be done on the backend?
    const firestore = firebaseReference.firestore();
    const now = new Date();

    const inviteeBatches = chunk(
      invitees,
      500 // Max size for a firestore batch
    ).map((chunkedInvitees) =>
      chunkedInvitees.reduce((batch, invitee) => {
        const registration = registrations.find(
          (x) => x.emailNormalized === invitee.email.toUpperCase()
        );
        const data = {
          ...invitee,
          emailNormalized: invitee.email.toUpperCase(),
          updatedAt: now,
          updatedBy: user.id,
          status: "invited",
        };

        return registration == null
          ? batch.set(
              firestore.collection(`events/${event.id}/registrants`).doc(),
              { ...data, createdAt: now, createdBy: user.id }
            )
          : batch.set(
              firestore.doc(
                `events/${event.id}/registrants/${registration.id}`
              ),
              data
            );
      }, firestore.batch())
    );

    Promise.all(inviteeBatches.map((x) => x.commit()))
      .then(() => {
        toastNotification({
          title: "SUCCESFULLY SENT",
          message: `Event: ${event.name}.`,
        });
        setSubmitting(false);
        setInviteesModalOpen(false);
        console.info("Successfully sent invitations", invitees);
      })
      .catch((error) => {
        toastNotification({
          title: "FAILED TO SEND",
          message: `Event: ${event.name}.`,
          type: "danger",
        });
        setSubmitting(false);
        console.error("Failed to send invitations", error);
      });
  }

  function onInviteesFormModalClose() {
    setInviteesModalOpen(false);
  }

  function onRegistrationTableRowClick(e, registration) {
    setRegistration(registration);
  }

  async function onPrintButtonClick() {
    const badges = selectedRegistrations.map(registrationToNameBadge);
    setPrintContent(<NameBadgeReport badges={badges} />);
    // Wait one frame for the report to render in the DOM before printing
    setTimeout(() => {
      window.print();
    });
  }

  function onClosePrintButtonClick() {
    setSelecting(false);
  }

  return (
    <div ref={pageRef}>
      <PageHeader stickyRef={pageRef}>
        {event.status === "published" && !disabled && (
          <div className={classes.controls}>
            {selecting ? (
              <Button
                primary
                disabled={selectedRegistrations.length === 0}
                onClick={onPrintButtonClick}
              >
                <Icon name="print" />
                Print
              </Button>
            ) : !event.full ? (
              <AddRegistrationButton options={options} />
            ) : (
              <Label className={classes.full}>Registration full</Label>
            )}

            {registrationsMenuOptions.length > 0 && (
              <>
                {selecting ? (
                  <IconButton
                    icon="close"
                    size="huge"
                    onClick={onClosePrintButtonClick}
                  />
                ) : (
                  <Dropdown
                    pointing="top right"
                    icon={null}
                    trigger={
                      <IconButton
                        type="button"
                        icon="ellipsis vertical"
                        size="huge"
                      />
                    }
                    options={registrationsMenuOptions}
                  />
                )}
              </>
            )}
          </div>
        )}
      </PageHeader>
      <Segment>
        {registrations.length === 0 ? (
          <>
            {event.status === "published" ? (
              <>
                <p>
                  There are currently no invitees or registrants for this event.
                </p>
              </>
            ) : (
              <p>There were no invitees or registrants for this event.</p>
            )}
          </>
        ) : (
          <RegistrationTable
            stickyControls
            stickyControlsOffset={64}
            pageSize={30}
            registrations={registrations}
            onRowClick={onRegistrationTableRowClick}
            rowOptions={disabled ? undefined : registrationMenuOptions}
            multiSelect={selecting}
            selectedRows={selectedRegistrations}
            onSelectedRowsChange={setSelectedRegistrations}
            eventType={event.type}
            askedForReleaseWaiver={event.askForReleaseWaiver}
            askedIfRepresentedByUnion={event.askIfRepresentedByUnion}
          />
        )}
      </Segment>

      {registration && (
        <RegistrationModal
          {...{
            event,
            registration,
            registrations,
            disabled,
            defaultValues: registrationToFormDefaultValues(registration, event),
          }}
          open={registration != null}
          onClose={setRegistration}
          onAccept={onRegistrationFormModalAccept}
        />
      )}

      {registrationToRemove && (
        <RemoveRegistrationModal
          event={event}
          registration={registrationToRemove}
          open={registrationToRemove != null}
          onClose={setRegistrationToRemove}
          onAccept={onRemoveRegistrationModalAccept}
        />
      )}

      {inviteesModalOpen && (
        <InviteesModal
          inviteeValidator={inviteeValidator(registrations, event.audience)}
          open={inviteesModalOpen}
          onClose={onInviteesFormModalClose}
          onAccept={onInviteesFormModalAccept}
        />
      )}
    </div>
  );
}

export default withResources(EventRegistrationPage);
