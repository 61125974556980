import React, { useState } from "react";
import EventSchedule from "./component/EventSchedule/EventSchedule";
import { Controller, useFormContext } from "react-hook-form";
import { addHours, set } from "date-fns";
import { Button } from "semantic-ui-react";
import WorkshopModal from "./component/WorkshopModal/WorkshopModal";
import classes from "./EventWorkshopSection.module.css";
import WorkshopFormFields from "./component/WorkshopForm/WorkshopFormFields";
import WorkshopForm from "./component/WorkshopForm/WorkshopForm";
import { workshopToFormValues } from "./component/WorkshopForm/workshopForms";
import DeleteWorkshopModal from "./component/DeleteWorkshopModal/DeleteWorkshopModal";
import { noWorkshopTimeOverlapPerRoom } from "./component/WorkshopForm/support/noWorkshopTimeOverlapPerRoom";
import { FormField } from "../../../../../../../shared/semantic-form";
import { selectError } from "../../../../../../../shared/form/support";
import { firestoreId } from "@sempra-event-registration/common";

const notNull = (value) => value != null;

function scrollElementIntoView(selector) {
  const element = document.querySelector(selector);
  if (element != null) {
    element.scrollIntoView({
      behavior: "smooth",
    });
  }
}

function setTimeOfDay(date, time) {
  return set(date, {
    hours: time.getHours(),
    minutes: time.getMinutes(),
    seconds: time.getSeconds(),
  });
}

function EventWorkshopSection({ fieldProps }) {
  const { disabled } = fieldProps;
  const [workshopToEdit, setWorkshopToEdit] = useState();
  const [workshopToDelete, setWorkshopToDelete] = useState();

  const {
    control,
    watch,
    setValue,
    errors,
    getValues,
    formState: { isDirty },
  } = useFormContext();

  const eventStartAndEndTime = getValues(["startTime", "endTime"]);

  function setValueInternal(name, value) {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  const date = watch("date");
  const startTime = watch("startTime");
  const endTime = watch("endTime");

  // when watching field array values you need this default value logic...
  // otherwise it will return the values passed to defaultValues when the form is created
  const rooms = watch("rooms", isDirty ? [] : undefined) || [];
  // localize the times to the event date for proper display
  const meals = (watch("meals", isDirty ? [] : undefined) || [])
    .filter(
      (meal) => meal.startTime instanceof Date && meal.endTime instanceof Date
    )
    .map((meal) => ({
      ...meal,
      startTime: setTimeOfDay(date, meal.startTime),
      endTime: setTimeOfDay(date, meal.endTime),
    }));
  const workshops = (watch("workshops", isDirty ? [] : undefined) || []).map(
    (x) => ({
      ...x,
      startTime: setTimeOfDay(date, x.startTime),
      endTime: setTimeOfDay(date, x.endTime),
    })
  );

  const unsatisfiedDependencies = [];
  const hasTimes =
    date instanceof Date &&
    startTime instanceof Date &&
    endTime instanceof Date;
  if (!hasTimes) {
    unsatisfiedDependencies.push({
      id: "date-and-time",
      name: "date, times",
    });
  }
  const hasRooms = rooms.filter(notNull).length > 0;
  if (!hasRooms) {
    unsatisfiedDependencies.push({
      id: "location",
      name: "rooms",
    });
  }

  function onAddWorkshopButtonClick() {
    setWorkshopToEdit({
      room: rooms[0],
      startTime: startTime,
      endTime: addHours(startTime, 1),
      name: `Workshop ${workshops.length + 1}`,
      facilitator: "",
      description: "",
    });
  }

  function helpText() {
    return (
      <div>
        <p>
          Before adding workshops, please enter the{" "}
          {unsatisfiedDependencies.map((dependency, index, dependencies) => (
            <React.Fragment key={index}>
              {index > 0
                ? index !== dependencies.length - 1
                  ? ", "
                  : " and "
                : ""}
              <button
                type="button"
                className={classes.link}
                onClick={() => scrollElementIntoView(`#${dependency.id}`)}
              >
                {dependency.name}
              </button>
            </React.Fragment>
          ))}
        </p>
      </div>
    );
  }

  function scheduleView() {
    function onWorkshopEditOptionClick(workshop) {
      setWorkshopToEdit(workshop);
    }

    function onWorkshopCloneOptionClick(workshop) {
      setValueInternal(
        "workshops",
        workshops.concat({
          ...workshop,
          id: firestoreId(),
        })
      );
    }

    function onWorkshopDeleteOptionClick(workshop) {
      setWorkshopToDelete(workshop);
    }

    function onDeleteWorkshopModalAccept(workshop) {
      setWorkshopToDelete();
      setValueInternal(
        "workshops",
        workshops.filter(({ id }) => id !== workshop.id)
      );
    }

    function onWorkshopChange(workshop) {
      setValueInternal(
        "workshops",
        workshops.map((x) => (x.id === workshop.id ? workshop : x))
      );
    }

    function onWorkshopModalAccept(values, workshop) {
      setWorkshopToEdit();
      const update = {
        ...workshop,
        ...values,
      };
      setValueInternal(
        "workshops",
        update.id != null
          ? workshops.map((x) => (x.id === update.id ? update : x))
          : workshops.concat({ id: firestoreId(), ...update })
      );
    }

    return (
      <>
        <div className={classes.controls}>
          {disabled || (
            <Button
              primary
              inverted
              type="button"
              onClick={onAddWorkshopButtonClick}
            >
              Add Workshop
            </Button>
          )}
          {workshopToEdit && (
            <WorkshopForm
              {...{
                defaultValues: workshopToFormValues(workshopToEdit),
                disabled,
              }}
            >
              <WorkshopModal
                open={workshopToEdit != null}
                onAccept={(values) =>
                  onWorkshopModalAccept(values, workshopToEdit)
                }
                onClose={() => setWorkshopToEdit()}
                {...{
                  workshop: workshopToEdit,
                  disabled,
                }}
              >
                <WorkshopFormFields
                  rooms={rooms}
                  disabled={disabled}
                  minTime={setTimeOfDay(date, startTime)}
                  maxTime={setTimeOfDay(date, endTime)}
                  eventTimes={eventStartAndEndTime}
                />
              </WorkshopModal>
            </WorkshopForm>
          )}
        </div>
        <EventSchedule
          {...{
            ...fieldProps,
            date,
            startTime: setTimeOfDay(date, startTime),
            endTime: setTimeOfDay(date, endTime),
            rooms,
            meals,
            workshops,
            onWorkshopChange,
            onWorkshopEditOptionClick,
            onWorkshopCloneOptionClick,
            onWorkshopDeleteOptionClick,
          }}
        />
        {workshopToDelete && (
          <DeleteWorkshopModal
            workshop={workshopToDelete}
            open={workshopToDelete != null}
            onClose={() => setWorkshopToDelete()}
            onAccept={() => onDeleteWorkshopModalAccept(workshopToDelete)}
          />
        )}
      </>
    );
  }

  return (
    <>
      {unsatisfiedDependencies.length > 0 ? helpText() : scheduleView()}

      <Controller
        control={control}
        name="workshops"
        rules={{
          validate: noWorkshopTimeOverlapPerRoom(),
        }}
        render={(props) => (
          <FormField error={selectError(() => errors.workshops)}>
            <input type="hidden" {...props} />
          </FormField>
        )}
      />
    </>
  );
}

export default EventWorkshopSection;
