import React from "react";
import { Input } from "../../../../../../../../../shared/react-hook-form";
import { requiredNotBlank } from "@sempra-event-registration/common";
import { selectError } from "../../../../../../../../../shared/form";
import classes from "./EventMealOption.module.css";

function EventMealOption({ index, field, namePrefix, errors, ...fieldProps }) {
  return (
    <div className={classes.grid}>
      <Input
        name={namePrefix + "name"}
        label={`Option ${index + 1}`}
        error={selectError(() => errors.name)}
        rules={{
          validate: requiredNotBlank("Required"),
        }}
        defaultValue={field.name || ""}
        {...fieldProps}
      />
      <Input
        name={namePrefix + "dietaryType"}
        label="Dietary Type"
        error={selectError(() => errors.dietaryType)}
        defaultValue={field.dietaryType || ""}
        {...fieldProps}
      />
    </div>
  );
}

export default EventMealOption;
