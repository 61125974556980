import React from "react";
import { Menu } from "semantic-ui-react";

function ReportsMenu({ options, activeOption, onOptionClick }) {
  return (
    <nav>
      <Menu vertical>
        <Menu.Item>
          <Menu.Header>Reports</Menu.Header>
        </Menu.Item>
        {options.map((option) => (
          <Menu.Item
            key={option.key}
            active={option.key === activeOption.key}
            onClick={() => onOptionClick(option)}
          >
            {option.title}
          </Menu.Item>
        ))}
      </Menu>
    </nav>
  );
}

export default ReportsMenu;
