import React from "react";
import { IconButton } from "../../../../../../../../../../../shared/ui";
import classes from "./ScheduledWorkshop.module.css";

function ScheduledWorkshop({
  workshop,
  disabled,
  onEditOptionClick = () => {},
  onCloneOptionClick = () => {},
  onDeleteOptionClick = () => {},
}) {
  const options = disabled
    ? [
        {
          icon: "eye",
          onClick: onEditOptionClick,
        },
      ]
    : [
        {
          icon: "edit outline",
          onClick: onEditOptionClick,
        },
        {
          icon: "copy outline",
          onClick: onCloneOptionClick,
        },
        {
          icon: "close",
          onClick: onDeleteOptionClick,
        },
      ];

  return (
    <div
      className={classes.workshop}
      onDoubleClick={() => onEditOptionClick(workshop)}
    >
      <div className={classes.topGrid}>
        <span className={classes.name}>{workshop.name}</span>
        <span className={classes.controls}>
          {options.map((option, index) => (
            <IconButton
              key={index}
              className={classes.menuButton}
              type="button"
              size="small"
              icon={option.icon}
              onClick={() => option.onClick(workshop)}
            />
          ))}
        </span>
      </div>
      <div className={classes.facilitator}>{workshop.facilitator}</div>
    </div>
  );
}

export default ScheduledWorkshop;
