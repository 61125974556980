import React from "react";
import { SemanticDataTable } from "../../../shared/ui";
import { comparing, stringComparator } from "@sempra-event-registration/common";
import { rolesToDisplayNamesMap } from "../../support/rolesToDisplayNamesMap";
import styles from "./AdminsTable.module.css";
import { adminGroupsToDisplayNamesMap } from "../../support/adminGroupsToDisplayNamesMap";

function AdminsTable({ rows, adminMenuOptions }) {
  const columns = [
    {
      id: "name",
      title: "Name",
      sortable: true,
      dataType: "string",
      value: (x) => x.name,
      cellProps: () => ({ collapsing: true }),
    },
    {
      id: "email",
      title: "Email Address",
      sortable: true,
      dataType: "string",
      value: (x) => x.email,
      cellProps: () => ({ collapsing: true }),
    },
    {
      id: "roles",
      title: "Roles",
      sortable: true,
      comparator: stringComparator,
      value: (x) => x.roles?.sort(stringComparator),
      sortableValue: (x) => x.roles?.sort(stringComparator).join(","),
      format: (roles) => {
        const roleLineItems = roles.map((role) => (
          <li key={role}>{rolesToDisplayNamesMap[role]}</li>
        ));
        return <ul className={styles.cellList}>{roleLineItems}</ul>;
      },
      cellProps: () => ({ collapsing: true }),
    },
    {
      id: "groups",
      title: "Groups",
      sortable: true,
      dataType: "string",
      value: (x) =>
        x.groups?.sort(comparing(stringComparator, (x) => x)).join(","),
      format: (groups) => {
        const groupLineItems = groups
          .split(",")
          .map((group) => (
            <li key={group}>{adminGroupsToDisplayNamesMap[group]}</li>
          ));
        return <ul className={styles.cellList}>{groupLineItems}</ul>;
      },
      cellProps: () => ({ collapsing: true }),
    },
  ];

  return (
    <SemanticDataTable
      striped
      singleLine
      stickyControls
      stickyControlsOffset={64}
      pageSize={30}
      columns={columns}
      rows={rows}
      rowOptions={adminMenuOptions}
      rowKeyProvider={(adminUser) => adminUser.id}
      defaultSort={[["name", "ascending"]]}
    />
  );
}

export default AdminsTable;
