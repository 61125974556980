import React from "react";
import PropTypes from "prop-types";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { IconButton } from "../../../ui";
import classes from "./FieldTree.module.css";
import { firestoreId } from "@sempra-event-registration/common";

function FieldTree({
  field: fieldDefinition,
  namePrefix = "",
  errors,
  disabled,
  ...rest
}) {
  const formContext = useFormContext();
  const { control } = formContext || rest;
  const {
    name,
    label,
    limit,
    empty = () => ({}),
    render,
    childFields,
  } = fieldDefinition;
  const fieldName = namePrefix + name;
  const fieldErrors = (errors || formContext.errors || {})[name] || [];
  const { fields, remove, append } = useFieldArray({
    control,
    name: fieldName,
  });
  const showAddButton = (limit == null || fields.length < limit) && !disabled;

  return (
    <div {...rest}>
      {fields.map((field, index) => {
        const childNamePrefix = `${fieldName}[${index}].`;
        const childProps = {
          namePrefix: childNamePrefix,
          errors: fieldErrors != null ? fieldErrors[index] || {} : {},
          disabled,
        };
        return (
          <div key={field.id}>
            <div className={classes.fieldRow}>
              <Controller
                name={childProps.namePrefix + "id"}
                control={control}
                defaultValue={field.id}
                render={(props) => <input type="hidden" {...props} />}
              />
              {render({
                index,
                field,
                ...childProps,
              })}
              {!disabled && (
                <IconButton
                  type="button"
                  icon="remove"
                  size="huge"
                  className={classes.removeButton}
                  onClick={() => {
                    remove(index);
                  }}
                />
              )}
            </div>
            {(childFields || []).map((childFieldDefinition, childIndex) => (
              <FieldTree
                key={childIndex}
                className={classes.child}
                field={childFieldDefinition}
                {...childProps}
              />
            ))}
          </div>
        );
      })}

      <div className={classes.addButtonContainer}>
        {showAddButton && (
          <button
            className={classes.buttonLink}
            type="button"
            disabled={disabled}
            onClick={() => {
              append({
                id: firestoreId(),
                ...empty(),
              });
            }}
          >
            Add {label}
          </button>
        )}
      </div>
    </div>
  );
}

FieldTree.defaultProps = {
  namePrefix: "",
  field: { empty: () => ({}) },
};

FieldTree.propTypes = {
  field: PropTypes.object,
  namePrefix: PropTypes.string.isRequired,
  errors: PropTypes.object,
};

export default FieldTree;
