import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { Controller } from "react-hook-form";
import {
  firebaseReference,
  firestoreCollectionQuery,
  requiredNotBlank,
} from "@sempra-event-registration/common";
import { selectError } from "../../../../../../../shared/form";
import { FormField } from "../../../../../../../shared/semantic-form";
import { defaultNameMaxLength } from "../../../../support/eventFormDefaults";

const maxLength = defaultNameMaxLength;

const useLocationOptions = firestoreCollectionQuery(() =>
  firebaseReference.firestore().collection("readonly_event_locations")
)
  .map((snapshot) =>
    snapshot.docs.map((document) => ({
      key: document.id,
      text: document.data().name,
      value: document.data().name,
      document,
    }))
  )
  .build();

function EventLocationName({
  control,
  disabled,
  errors,
  onLocationChange,
  ...rest
}) {
  const { value: defaultOptions = [] } = useLocationOptions();
  const [addedOption, setAddedOption] = useState();
  const options =
    addedOption != null ? [...defaultOptions, addedOption] : defaultOptions;

  function findLocationForSearch(value) {
    return options.find((option) => option.text === value)?.document?.data();
  }

  return (
    <Controller
      name="locationName"
      control={control}
      rules={{
        validate: requiredNotBlank("Required"),
        maxLength: {
          value: maxLength,
          message: `Cannot exceed ${maxLength} characters`,
        },
      }}
      render={({ value, onChange, onBlur }) => (
        <FormField
          label="Name"
          disabled={disabled}
          error={selectError(() => errors.locationName)}
        >
          <Dropdown
            options={options}
            disabled={disabled}
            fluid
            search
            selection
            allowAdditions
            searchQuery={value}
            onSearchChange={(event, { searchQuery }) => onChange(searchQuery)}
            onChange={(event, { value }) => {
              const location = findLocationForSearch(value);
              if (location != null) {
                onLocationChange(location);
              }
            }}
            onAddItem={(event, { value }) => {
              setAddedOption({ key: value, text: value, value });
            }}
            onBlur={(event, { value }) => {
              onBlur(event);
              const location = findLocationForSearch(value);
              if (location != null) {
                onLocationChange(location);
              } else {
                setAddedOption({ key: value, text: value, value });
              }
            }}
            {...rest}
          />
        </FormField>
      )}
    />
  );
}

export default EventLocationName;
