import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { selectError } from "../../../../../../../../../shared/form/support";
import {
  requiredNotBlank,
  timeIsBefore,
} from "@sempra-event-registration/common";
import { FormField } from "../../../../../../../../../shared/semantic-form";
import ReactDatePicker from "react-datepicker";
import { addMinutes, subMinutes } from "date-fns";
import classes from "./WorkshopFormFields.module.css";
import {
  defaultEventMaxTime,
  defaultEventMinTime,
  defaultNameMaxLength,
  defaultEventWorkshopDurationInMinutes,
} from "../../../../../../support/eventFormDefaults";
import { validateWithinTimeBoundaries } from "../../../../../../support/validateWithinTimeBoundaries";

function roomToOption(room) {
  return {
    key: room.id,
    text: room.name,
    value: room.id,
  };
}

function StartTime({ disabled, minTime, maxTime, eventTimes }) {
  const { control, errors, watch } = useFormContext();
  const endTime = watch("endTime");

  return (
    <Controller
      control={control}
      name="startTime"
      rules={{
        required: "Required",
        validate: async (startTime) => {
          const startTimeIsBeforeEndTimeError = timeIsBefore(
            startTime,
            endTime,
            "Start time must occur before end time"
          );

          if (startTimeIsBeforeEndTimeError) {
            return startTimeIsBeforeEndTimeError;
          }

          return validateWithinTimeBoundaries(startTime, eventTimes, "start");
        },
      }}
      render={({ onChange, onBlur, value }) => {
        return (
          <FormField
            label="Start Time"
            error={selectError(() => errors.startTime)}
            {...{ disabled }}
          >
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="h:mm aa"
              minTime={minTime}
              maxTime={subMinutes(
                endTime != null ? endTime : maxTime,
                defaultEventWorkshopDurationInMinutes
              )}
              selected={value}
              {...{ onChange, onBlur, disabled }}
            />
          </FormField>
        );
      }}
    />
  );
}

function EndTime({ disabled, minTime, maxTime, eventTimes }) {
  const { control, errors, watch } = useFormContext();
  const startTime = watch("startTime");

  return (
    <Controller
      control={control}
      name="endTime"
      rules={{
        required: "Required",
        validate: async (endTime) => {
          const startTimeIsBeforeEndTimeMessage = timeIsBefore(
            startTime,
            endTime,
            "End time must occur after start time"
          );

          if (startTimeIsBeforeEndTimeMessage) {
            return startTimeIsBeforeEndTimeMessage;
          }

          return validateWithinTimeBoundaries(endTime, eventTimes, "end");
        },
      }}
      render={({ onChange, onBlur, value }) => {
        return (
          <FormField
            label="End Time"
            error={selectError(() => errors.endTime)}
            {...{ disabled }}
          >
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              minTime={addMinutes(
                startTime != null ? startTime : minTime,
                defaultEventWorkshopDurationInMinutes
              )}
              maxTime={maxTime}
              timeIntervals={15}
              dateFormat="h:mm aa"
              selected={value}
              {...{ onChange, onBlur, disabled }}
            />
          </FormField>
        );
      }}
    />
  );
}

function WorkshopFormFields({
  rooms,
  disabled,
  minTime = defaultEventMinTime,
  maxTime = defaultEventMaxTime,
  eventTimes,
}) {
  const { control, errors } = useFormContext();
  return (
    <Form className={disabled ? "readonly" : ""}>
      <Controller
        control={control}
        name="name"
        rules={{
          validate: requiredNotBlank("Required"),
          maxLength: {
            value: defaultNameMaxLength,
            message: `Cannot exceed ${defaultNameMaxLength} characters`,
          },
        }}
        render={({ value, onChange, onBlur }) => (
          <Form.Input
            label="Name"
            type="text"
            value={value || ""}
            error={selectError(() => errors.name)}
            onChange={(event, { value }) => onChange(value)}
            {...{ disabled, onBlur }}
          />
        )}
      />

      <div className={classes.row}>
        <Controller
          control={control}
          name="room"
          render={({ value, onChange, onBlur }) => (
            <Form.Select
              label="Room"
              options={rooms.map(roomToOption)}
              value={value.id}
              onChange={(e, { value }) =>
                onChange(rooms.find(({ id }) => id === value))
              }
              {...{ disabled, onBlur }}
            />
          )}
        />

        <StartTime {...{ disabled, minTime, maxTime, eventTimes }} />
        <EndTime {...{ disabled, minTime, maxTime, eventTimes }} />
      </div>

      <Controller
        control={control}
        name="facilitator"
        render={({ value, onChange, onBlur }) => (
          <Form.Input
            label="Facilitator"
            type="text"
            value={value || ""}
            error={selectError(() => errors.facilitator)}
            onChange={(event, { value }) => onChange(value)}
            {...{ disabled, onBlur }}
          />
        )}
      />

      <Controller
        control={control}
        name="description"
        // TODO required?
        // rules={{
        //   validate: requiredNotBlank("Required")
        // }}
        render={({ value, onChange, onBlur }) => (
          <Form.TextArea
            label="Description"
            value={value || ""}
            error={selectError(() => errors.description)}
            onChange={(event, { value }) => onChange(value)}
            {...{ disabled, onBlur }}
          />
        )}
      />
    </Form>
  );
}

export default WorkshopFormFields;
