import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ReactNotification from "react-notifications-component";
import {
  UserProvider,
  RemoteConfigProvider,
  useRemoteConfigFlags,
  ScrollToTop,
} from "@sempra-event-registration/common";
import ProtectedRoute from "./shared/auth/component/ProtectedRoute/ProtectedRoute";
import EventPage from "./event/page/EventPage/EventPage";
import EventsPage from "./event/page/EventsPage/EventsPage";
import Header from "./shared/ui/component/Header/Header";
import { Loader } from "./shared/ui";
import SignedOut from "./shared/auth/component/SignedOut";
import { PrintArea, PrintProvider } from "./shared/print";
import AdminsPage from "./admins/page/AdminsPage";
import classes from "./App.module.css";

function AppProviders({ children }) {
  return (
    <RemoteConfigProvider>
      <UserProvider>
        <PrintProvider>{children}</PrintProvider>
      </UserProvider>
    </RemoteConfigProvider>
  );
}

function AppContent() {
  const flags = useRemoteConfigFlags();
  if (flags.status !== "loaded") return <Loader />;

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <main className={classes.main}>
        <Switch>
          <ProtectedRoute
            path="/admins"
            hasAnyPermission={[
              "sdge_external_event_admin_write",
              "internal_event_admin_write",
              "sdge_employee_event_admin_write",
            ]}
          >
            <Route exact path="/admins" component={AdminsPage} />
          </ProtectedRoute>
          <ProtectedRoute
            path="*"
            hasAnyPermission={[
              "sdge_external_event_write",
              "internal_event_write",
              "sdge_employee_event_write",
            ]}
          >
            <Route path="/events/:eventId" component={EventPage} />
            <Route exact path="/events" component={EventsPage} />
            <Route
              exact
              path="/"
              render={() => (
                <Redirect to={`/events${window.location.search}`} />
              )}
            />
          </ProtectedRoute>
        </Switch>
      </main>
      <footer />
    </Router>
  );
}

function App() {
  return (
    <Router>
      <Switch>
        {/* This route was added here as anything lower would load the userProvider, and that authenticates a user, this prevents that from happening.  */}
        <Route exact path="/signout">
          <SignedOut />
        </Route>
        <Route path="/">
          <AppProviders>
            <ReactNotification />
            <AppContent />

            {/* only displayed when printing */}
            <PrintArea />
          </AppProviders>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
