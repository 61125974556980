import React from "react";
import { Loader as SemanticLoader } from "semantic-ui-react";

function Loader() {
  return (
    <div>
      <SemanticLoader active size="massive">
        Loading
      </SemanticLoader>
    </div>
  );
}

export default Loader;
