import React from "react";
import {
  groupWorkshopsByStartTime,
  timeOf,
} from "@sempra-event-registration/common";
import { Table, Checkbox as SemanticCheckbox } from "semantic-ui-react";
import { format, isEqual } from "date-fns";
import classes from "./RegistrationWorkshops.module.css";
import { Controller, useFormContext } from "react-hook-form";
import { timeRangesIntersect } from "@sempra-event-registration/common/src/date";

function TimeRange({ from, to, format: formatting = "h:mm aa" }) {
  return (
    <div>
      <span>{format(from, formatting)} </span>
      <span>- {format(to, formatting)}</span>
    </div>
  );
}

function WorkshopTable({ workshops: denormalizedWorkshops, disabled }) {
  const { watch, setValue, getValues } = useFormContext();
  const selectedWorkshopIds = watch("workshops", getValues().workshops || []);

  // TODO should be done at workshop save time and nowhere else
  const workshops = denormalizedWorkshops.map((x) => ({
    ...x,
    startTime: timeOf(x.startTime),
    endTime: timeOf(x.endTime),
  }));
  const workshopsByStartTime = groupWorkshopsByStartTime(workshops);

  function onWorkshopRowClick(workshop) {
    setValue(
      "workshops",
      !selectedWorkshopIds.includes(workshop.id)
        ? [
            ...selectedWorkshopIds.filter(
              (x) =>
                // only works because they are grouped by start time
                !isEqual(
                  workshops.find((y) => y.id === x).startTime,
                  workshop.startTime
                )
            ),
            workshop.id,
          ]
        : selectedWorkshopIds.filter((x) => x !== workshop.id),
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
  }

  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Time</Table.HeaderCell>
          <Table.HeaderCell>Workshop</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {workshopsByStartTime.map((group, index) => (
          <React.Fragment key={index}>
            {group.values.map((workshop) => {
              const checked = selectedWorkshopIds.some(
                (x) => x === workshop.id
              );

              // Ideally you should be able to select another like a radio button group
              // Question to the users will be how-often are there overlapping workshops
              // TODO capacity checking for workshop room/time to see if workshop is full
              const checkboxDisabled =
                disabled ||
                (!checked &&
                  selectedWorkshopIds.some((x) => {
                    const eventWorkshop = workshops.find((y) => y.id === x);
                    // Only disable options in other start-time groups that have overlapping time ranges
                    return (
                      !isEqual(eventWorkshop.startTime, workshop.startTime) &&
                      timeRangesIntersect(
                        eventWorkshop.startTime,
                        eventWorkshop.endTime,
                        workshop.startTime,
                        workshop.endTime
                      )
                    );
                  }));

              return (
                <Table.Row
                  key={workshop.id}
                  onClick={() =>
                    checkboxDisabled || onWorkshopRowClick(workshop)
                  }
                  className={`${classes.row} ${
                    checkboxDisabled ? classes.disabled : ""
                  }`}
                >
                  <Table.Cell className={classes.workshopSelected}>
                    <SemanticCheckbox
                      checked={checked}
                      disabled={checkboxDisabled}
                    />
                  </Table.Cell>
                  <Table.Cell className={classes.workshopTime}>
                    <TimeRange
                      from={workshop.startTime}
                      to={workshop.endTime}
                    />
                  </Table.Cell>
                  <Table.Cell className={classes.workshopName}>
                    {workshop.name || ""}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </React.Fragment>
        ))}
      </Table.Body>
    </Table>
  );
}

function RegistrationWorkshops({ control, disabled, event }) {
  const { workshops = [] } = event;
  return (
    <>
      <Controller
        control={control}
        name="workshops"
        render={(props) => <input type="hidden" {...props} />}
      />
      <WorkshopTable workshops={workshops} disabled={disabled} />
    </>
  );
}

export default RegistrationWorkshops;
