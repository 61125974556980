import React from "react";
import { FieldTree } from "../../../../../../../shared/react-hook-form";
import EventDocument from "./component/EventDocument/EventDocument";

function EventDocumentsSection({ fieldProps }) {
  const field = {
    name: "documents",
    label: "Document",
    limit: 10,
    render: (props) => <EventDocument {...{ ...fieldProps, ...props }} />,
    empty: () => ({ name: "" }),
  };

  return <FieldTree {...{ field, ...fieldProps }} />;
}

export default EventDocumentsSection;
