import React, { useState } from "react";
import { Modal, Button, Checkbox } from "semantic-ui-react";
import classes from "./SaveEventModal.module.css";

function SaveEventModal({ onAccept, onClose, ...rest }) {
  const [sendChangeNotifications, setSendChangeNotifications] = useState(false);

  return (
    <Modal
      size="tiny"
      dimmer="blurring"
      centered={false}
      onClose={onClose}
      {...rest}
    >
      <Modal.Header>Save</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Check the box below if you would like to notify registrants of your
            changes.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div className={classes.controls}>
          <div>
            <Checkbox
              label="Send change notification"
              checked={sendChangeNotifications}
              onChange={() =>
                setSendChangeNotifications(!sendChangeNotifications)
              }
            />
          </div>
          <div>
            <Button
              primary
              onClick={() => {
                onAccept({ sendChangeNotifications });
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default SaveEventModal;
