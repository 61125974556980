import React from "react";
import { Checkbox } from "../../../../../../../shared/react-hook-form";

function EventVirtualMeetingLink({ control, disabled, setValue }) {
  return (
    <Checkbox
      {...{ control, disabled }}
      name="isVirtualEvent"
      label="Virtual Event"
      onChange={(checked) => {
        if (checked) {
          setValue("capacity", 250, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }}
    />
  );
}

export default EventVirtualMeetingLink;
