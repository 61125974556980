import React, { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { subHours, addHours, startOfDay, endOfDay } from "date-fns";
import classes from "./EventSchedule.module.css";
import ScheduledWorkshop from "./component/ScheduledWorkshop/ScheduledWorkshop";

// TODO move to remove config?
const schedulerLicenseKey = "0525543777-fcs-1602628235";
const visibleBoundaryHours = 1;

function roomToResource(room, index) {
  return {
    id: room.id,
    title: room.name || `(Unnamed Room ${index + 1})`,
  };
}

function mealToEvent(meal, resourceIds) {
  const { id, name: title, startTime: start, endTime: end } = meal;
  return {
    id,
    title,
    start,
    end,
    resourceIds,
    groupId: id,
    display: "background",
    editable: false,
    startEditable: false,
    durationEditable: false,
    resourceEditable: false,
    extendedProps: {
      meal,
    },
  };
}

function workshopToEvent(workshop, disabled) {
  const {
    id,
    room: { id: resourceId },
    name: title,
    startTime: start,
    endTime: end,
  } = workshop;
  return {
    id,
    resourceId,
    title,
    start,
    end,
    editable: !disabled,
    startEditable: !disabled,
    durationEditable: !disabled,
    resourceEditable: !disabled,
    extendedProps: {
      workshop,
    },
  };
}

function dateToTimeConfigString(date) {
  return [
    date.getHours().toString().padStart(2, "0"),
    date.getMinutes().toString().padStart(2, "0"),
    date.getSeconds().toString().padStart(2, "0"),
  ].join(":");
}

function dateToHourConfigString(date) {
  return [
    date.getHours().toString().padStart(2, "0"),
    date.getMinutes().toString().padStart(2, "0"),
  ].join(":");
}

// https://fullcalendar.io/docs/vertical-resource-view
// https://fullcalendar.io/docs/date-display
function EventSchedule({
  rooms,
  meals,
  workshops,
  date,
  startTime,
  endTime,
  disabled,
  errors,
  onWorkshopClick = () => {},
  onWorkshopChange = () => {},
  onWorkshopEditOptionClick = () => {},
  onWorkshopCloneOptionClick = () => {},
  onWorkshopDeleteOptionClick = () => {},
}) {
  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi.gotoDate(date);
    calendarApi.removeAllEventSources();
    calendarApi.addEventSource(events);
  }, [date]);

  const resources = rooms.map(roomToResource);
  let calendarRef = React.createRef();

  const resourceOrder = (a, b) => {
    const indexA = rooms.indexOf(a);
    const indexB = rooms.indexOf(b);
    return indexA - indexB;
  };

  const boundaryEvents = [
    {
      id: "before",
      title: "",
      start: startOfDay(date),
      end: startTime,
      display: "background",
      className: classes.boundaryEvent,
    },
    {
      id: "after",
      title: "",
      start: endTime,
      end: endOfDay(date),
      display: "background",
      className: classes.boundaryEvent,
    },
  ];

  const events = [
    ...boundaryEvents,
    ...meals.map((meal) =>
      mealToEvent(
        meal,
        resources.map(({ id }) => id)
      )
    ),
    ...workshops.map((workshop) => workshopToEvent(workshop, disabled)),
  ];

  function renderEventContent(event) {
    const { workshop } = event.event.extendedProps || {};
    if (workshop != null) {
      return (
        <ScheduledWorkshop
          {...{
            event: event.event,
            workshop,
            onEditOptionClick: onWorkshopEditOptionClick,
            onCloneOptionClick: onWorkshopCloneOptionClick,
            onDeleteOptionClick: onWorkshopDeleteOptionClick,
            disabled,
          }}
        />
      );
    }
    return event.title;
  }

  function onEventChange(e) {
    const {
      event: {
        extendedProps: { workshop },
        start: startTime,
        end: endTime,
        _def: {
          resourceIds: [roomId],
        },
      },
    } = e;
    onWorkshopChange({
      ...workshop,
      room: rooms.find(({ id }) => id === roomId),
      startTime,
      endTime,
    });
  }

  return (
    <FullCalendar
      ref={calendarRef}
      contentHeight="auto"
      plugins={[resourceTimeGridPlugin, interactionPlugin]}
      initialView="resourceTimeGridDay"
      initialDate={date}
      headerToolbar={null}
      allDaySlot={false}
      slotDuration="00:15:00"
      slotLabelInterval="01:00:00"
      eventConstraint={{
        startTime: dateToHourConfigString(startTime),
        endTime: dateToHourConfigString(endTime),
      }}
      slotMinTime={dateToTimeConfigString(
        subHours(startTime, visibleBoundaryHours)
      )}
      slotMaxTime={dateToTimeConfigString(
        addHours(endTime, visibleBoundaryHours)
      )}
      eventOverlap={(still, moving) =>
        // Can't overlap meals
        still.extendedProps.meal == null
      }
      {...{
        schedulerLicenseKey,
        resources,
        resourceOrder,
        events,
        eventChange: onEventChange,
        eventContent: renderEventContent,
      }}
    />
  );
}

export default EventSchedule;
