import React from "react";
import { Controller } from "react-hook-form";
import EventFeatureImageUploader from "./EventFeatureImageUploader/EventFeatureImageUploader";
import { InfoButton } from "../../../../../../../shared/ui";
import { Form } from "semantic-ui-react";

function EventFeatureImage({ control, disabled }) {
  return (
    <Controller
      name="image"
      control={control}
      render={({ value, onChange, ref, ...rest }) => (
        <Form.Field disabled={disabled}>
          <label>
            <span>Feature Image</span>
            <InfoButton
              content={
                <>
                  <h5>Feature Image Requirements</h5>
                  <p>For best results, select an image that is 1200x400.</p>
                  <p>
                    Note: If an image without a 3:1 aspect ratio is used, the
                    top and bottom may be cut off when displayed. Refer to the
                    preview to know exactly how the image will be displayed to
                    users.
                  </p>
                </>
              }
            />
          </label>
          <EventFeatureImageUploader
            {...rest}
            value={value?.url}
            onChange={(file) => {
              // stash the file reference in the form state for saving on submission
              const next = file != null ? { ...value, file } : {};
              onChange(next);
            }}
            disabled={disabled}
          />
        </Form.Field>
      )}
    />
  );
}

export default EventFeatureImage;
