import React, { createRef, useState } from "react";
import { useInternalUsersWithAdminRoles } from "../hook/useInternalUsersWithAdminRoles";
import {
  Error,
  stringComparator,
  useLoadedUser,
} from "@sempra-event-registration/common";
import { Segment } from "semantic-ui-react";
import { PageHeader } from "../../shared/ui";
import Loader from "../../shared/ui/component/Loader/Loader";
import AdminsTable from "../component/AdminsTable/AdminsTable";
import CreateAdminButton from "../component/CreateAdminButton/CreateAdminButton";
import { adminMenuOptionsFactory } from "../support/adminMenuOptionsFactory";
import AdminsModal from "../component/AdminsModal/AdminsModal";
import { saveInternalUser } from "../support/saveInternalUser";
import toastNotification from "../../shared/toastNotification/toastNotification";
import RemoveAdminModal from "../component/RemoveAdminModal/RemoveAdminModal";

/**
 * Combine the admin user record and the form values from the modal into an <code>internal_users</code> document.
 *
 * @param adminUser The administrative user being edited/created
 * @param formValues The data from the form's controls
 * @returns {{roles: string[], groups: string[]}} The administrative-level user with the <code>groups</code> and
 * <code>roles</code> added.
 */
function mapAdminUserToInternalUserDocument(adminUser, formValues) {
  const { roles } = formValues;

  // A user should only be associated to groups if the user's roles includes sdge_external_event_admin.  Otherwise, the
  // groups should be removed.
  const groups = roles.includes("sdge_external_event_admin")
    ? formValues.groups
    : [];

  return {
    ...adminUser,
    roles,
    groups,
  };
}

/**
 * Map the selected administrative-level user to a model that the input form expects.
 *
 * @param adminUser The administrative-level user being added/edited.
 * @returns {{roles: string[], name: string, groups: string[], id: string}}
 */
function mapAdminUserToFormValues(adminUser) {
  const {
    id,
    name,
    firstName,
    lastName,
    email,
    roles = [],
    groups = [],
  } = adminUser;

  return { id, name, firstName, lastName, email, roles, groups };
}

function AdminsPage() {
  const user = useLoadedUser();
  const internalUsersResource = useInternalUsersWithAdminRoles(
    user.permissions
  );
  const pageRef = createRef();
  const [adminUser, setAdminUser] = useState({});
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showRemoveAdminModal, setShowRemoveAdminModal] = useState(false);
  const [isNew, setIsNew] = useState(false);

  if (
    internalUsersResource.status === "loading" ||
    internalUsersResource.status === "empty"
  ) {
    return <Loader />;
  }
  if (internalUsersResource.status === "error") {
    return <Error message={internalUsersResource.error.message} />;
  }

  const internalUsers = internalUsersResource.value;

  // Do not show the logged-in user on the list of admin-level users.
  const allOtherUsers = internalUsers.filter(
    (internalUser) => stringComparator(user.id, internalUser.id) !== 0
  );

  function onCreateAdminButtonClick() {
    setShowAdminModal(true);
    setIsNew(true);
    setAdminUser(
      mapAdminUserToFormValues({
        id: "",
        roles: [],
        groups: [],
      })
    );
  }

  function onEditOptionClick(adminUser) {
    setIsNew(false);
    setAdminUser(mapAdminUserToFormValues(adminUser));

    setShowAdminModal(true);
  }

  function onRemoveOptionClick(adminUser) {
    setAdminUser(adminUser);

    setShowRemoveAdminModal(true);
  }

  async function onAdminsModalFormAccept(formValues, { setSubmitting }) {
    setSubmitting(true);

    // Map the user to an internal user document
    const internalUserToUpsert = mapAdminUserToInternalUserDocument(
      adminUser,
      formValues
    );

    // Save to the internal_users collection
    saveInternalUser(user, internalUserToUpsert)
      .then(() => {
        toastNotification({
          title: "SAVED SUCCESSFULLY",
          message: `Changes to admin user have been saved.`,
        });

        setAdminUser({});
      })
      .catch((error) => {
        console.error(
          `An error occurred while trying to save admin user ${adminUser.id} to the internal_users collection`,
          error
        );

        toastNotification({
          title: "ERROR WHILE SAVING",
          message: `An error occurred while trying to save admin user ${adminUser.firstName} ${adminUser.lastName}`,
          type: "danger",
        });
      })
      .finally(() => {
        setSubmitting(false);
        setShowAdminModal(false);
      });
  }

  function onRemoveAdminModalAccept(formValues, { setSubmitting }) {
    setSubmitting(true);

    // Map the user to an internal user document.  In this case, remove the groups and roles from the user's record but
    // leave the overall internal_users record intact; this is effectively a "soft delete".
    const internalUserToRemove = mapAdminUserToInternalUserDocument(adminUser, {
      ...formValues,
      roles: [],
      groups: [],
    });

    saveInternalUser(user, internalUserToRemove)
      .then(() => {
        setShowRemoveAdminModal(false);

        toastNotification({
          title: "REMOVED SUCCESSFULLY",
          message: `${formValues.name} has been removed and no longer has administrative privileges.`,
        });

        setAdminUser({});
      })
      .catch((error) => {
        console.error(
          `An error occurred while trying to remove groups and roles from user ${formValues.id}`,
          error
        );

        toastNotification({
          title: "ERROR WHILE REMOVING",
          message: `An error occurred while trying to remove ${formValues.name} from the list of admin users.`,
          type: "danger",
        });
      })
      .finally(() => setSubmitting(false));
  }

  const adminMenuOptions = adminMenuOptionsFactory({
    user,
    onEditOptionClick,
    onRemoveOptionClick,
  });

  return (
    <div ref={pageRef}>
      <PageHeader stickyRef={pageRef}>
        <CreateAdminButton onClick={onCreateAdminButtonClick} />
      </PageHeader>
      <Segment>
        <AdminsTable rows={allOtherUsers} adminMenuOptions={adminMenuOptions} />
      </Segment>

      {showAdminModal && (
        <AdminsModal
          adminUsers={internalUsers}
          adminUser={adminUser}
          setAdminUser={setAdminUser}
          open={showAdminModal}
          isNew={isNew}
          onAccept={onAdminsModalFormAccept}
          onClose={() => {
            setShowAdminModal(false);
            setAdminUser({});
          }}
        />
      )}

      {showRemoveAdminModal && (
        <RemoveAdminModal
          adminUser={adminUser}
          open={showRemoveAdminModal}
          onAccept={onRemoveAdminModalAccept}
          onClose={() => {
            setShowRemoveAdminModal(false);
            setAdminUser({});
          }}
        />
      )}
    </div>
  );
}

export default AdminsPage;
