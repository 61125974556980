import React, { createRef, useState } from "react";
import {
  Error,
  firebaseReference,
  isBlank,
  useLoadedUser,
} from "@sempra-event-registration/common";
import EventTable from "./component/EventTable/EventTable";
import { useEvents } from "./hook/useEvents";
import { useRouteMatch } from "react-router-dom";
import CreateEventButton from "./component/CreateEventButton/CreateEventButton";
import { permissionsToWritableEventTypeOptions } from "./support/eventTypeOptions";
import classes from "./EventsPage.module.css";
import Loader from "../../../shared/ui/component/Loader/Loader";
import { eventMenuOptionFactory } from "../../support/eventMenuOptionFactory";
import { Segment } from "semantic-ui-react";
import DeleteEventModal from "../../component/DeleteEventModal/DeleteEventModal";
import CancelEventModal from "../../component/CancelEventModal/CancelEventModal";
import { useAppConfig } from "../../../shared/config";
import EventFilters from "./component/EventFilters/EventFilters";
import {
  filtersToQuery,
  queryToComparator,
  queryToFilter,
  queryToFilters,
  queryToSearch,
  searchToQuery,
} from "./support/eventsQuery";
import { startOfToday } from "date-fns";
import { PageHeader } from "../../../shared/ui";
import { useSearchRestoringHistory } from "../../../shared/router";
import toastNotification from "../../../shared/toastNotification/toastNotification";

function EventsPage() {
  const { path } = useRouteMatch();
  const { value: appConfig } = useAppConfig();
  const user = useLoadedUser();
  const history = useSearchRestoringHistory();
  const [eventToDelete, setEventToDelete] = useState();
  const [eventToCancel, setEventToCancel] = useState();

  const pageRef = createRef();

  const defaultEventsQuery = {
    by: user.id,
    from: startOfToday(),
  };

  const query = isBlank(window.location.search)
    ? defaultEventsQuery
    : searchToQuery(window.location.search);

  const filters = {
    search: "",
    onlyMyEvents: true,
    onlyPastEvents: false,
    ...queryToFilters(query, user),
  };

  const createEventOptions = permissionsToWritableEventTypeOptions(
    user.permissions
  );

  const eventsResource = useEvents(user);

  if (
    eventsResource.status === "loading" ||
    eventsResource.status === "empty"
  ) {
    return <Loader />;
  }
  if (eventsResource.status === "error") {
    return <Error message={eventsResource.error.message} />;
  }

  const events = eventsResource.value
    .filter(queryToFilter(query))
    .sort(queryToComparator(query));

  function onFiltersChange(values) {
    history.replace({
      path,
      search: queryToSearch(filtersToQuery({ ...filters, ...values }, user)),
    });
  }

  function onCreateButtonClick(type) {
    history.push(`${path}/${type}`);
  }

  function onRowClick(event) {
    history.push(`${path}/${event.id}`);
  }

  function onDeleteOptionClick(event) {
    setEventToDelete(event);
  }

  function onDeleteModalAccept() {
    setEventToDelete();
    return firebaseReference
      .firestore()
      .collection("events")
      .doc(eventToDelete.id)
      .delete()
      .then(() =>
        toastNotification({
          title: "SUCCESFULLY DELETED",
          message: `Event: ${eventToDelete.name}`,
        })
      )
      .catch((error) =>
        toastNotification({
          title: "ERROR WHILE DELETING",
          message: "There was an error while deleting.",
          type: "danger",
        })
      );
  }

  function onCancelOptionClick(event) {
    setEventToCancel(event);
  }

  function onCancelModalAccept() {
    setEventToCancel();
    return firebaseReference
      .firestore()
      .collection("events")
      .doc(eventToCancel.id)
      .set({ status: "cancelled" }, { merge: true })
      .then(() =>
        toastNotification({
          title: "SUCCESFULLY CANCELLED",
          message: `Event: ${eventToCancel.name}`,
        })
      )
      .catch((error) => {
        toastNotification({
          title: "ERROR WHILE CANCELLING",
          message: "There was an error while cancelling.",
          type: "danger",
        });
        console.error("Failed to cancel event", error);
      });
  }

  const rowOptions = eventMenuOptionFactory({
    user,
    onDeleteOptionClick,
    onCancelOptionClick,
    ...appConfig,
  });

  return (
    <div ref={pageRef}>
      <PageHeader stickyRef={pageRef}>
        <CreateEventButton
          options={createEventOptions}
          onClick={onCreateButtonClick}
        />
      </PageHeader>
      <Segment className={classes.atmSegment}>
        {eventsResource.value === 0 ? (
          "There are no events matching your search."
        ) : (
          <EventTable
            onSearchChange={(search) => onFiltersChange({ search })}
            events={events}
            initialSearch={query.search}
            controls={
              <EventFilters value={filters} onChange={onFiltersChange} />
            }
            showTypeColumn={createEventOptions.length > 1}
            {...{ rowOptions, onRowClick }}
          />
        )}
      </Segment>
      {eventToDelete && (
        <DeleteEventModal
          event={eventToDelete}
          open={eventToDelete != null}
          onClose={() => setEventToDelete()}
          onAccept={onDeleteModalAccept}
        />
      )}
      {eventToCancel && (
        <CancelEventModal
          event={eventToCancel}
          open={eventToCancel != null}
          onClose={() => setEventToCancel()}
          onAccept={onCancelModalAccept}
        />
      )}
    </div>
  );
}

export default EventsPage;
