import React from "react";
import { Dropdown } from "semantic-ui-react";
import { Controller } from "react-hook-form";
import { requiredNotBlank } from "@sempra-event-registration/common";
import { selectError } from "../../../../../../../shared/form/support";
import { FormField } from "../../../../../../../shared/semantic-form";

function EventCompanyName({ control, disabled, errors }) {
  const options = [
    { key: "Sempra", text: "Sempra", value: "sempra" },
    { key: "SDG&E", text: "SDG&E", value: "sdge" },
    { key: "SoCalGas", text: "SoCalGas", value: "scg" },
  ];
  return (
    <Controller
      name="company"
      control={control}
      rules={{
        validate: requiredNotBlank("Required"),
      }}
      render={({ value, onBlur, onChange }) => (
        <FormField
          label="Company"
          disabled={disabled}
          error={selectError(() => errors.company)}
        >
          <Dropdown
            placeholder="Select Company"
            {...{
              options,
              disabled,
              onBlur,
              value,
            }}
            fluid
            selection
            onChange={(event, { value }) => {
              onChange(value);
            }}
          />
        </FormField>
      )}
    />
  );
}

export default EventCompanyName;
