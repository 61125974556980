import {
  DEFAULT_IMAGE,
  firebaseReference,
  promiseMutation,
} from "@sempra-event-registration/common";
import cloneDeepWith from "lodash.clonedeepwith";

function parseFileExtension(value) {
  const matches = /\.([0-9a-z]+)$/i.exec(value);
  if (matches != null) {
    return matches[1];
  }
}

function firebaseSafe(event) {
  // Firestore throws errors for field: undefined
  return cloneDeepWith(event, (value) => {
    if (value == null) {
      return null;
    }
    if (typeof value === "string") {
      return value.trim();
    }
  });
}

async function store(directory, file, downloadName) {
  try {
    const { name, type } = file;
    const generatedId = firebaseReference.firestore().collection("any").doc()
      .id;
    const storagePath = `${directory}/${generatedId}`;
    const firebaseStorageReference = firebaseReference
      .storage()
      .ref()
      .child(storagePath);

    await firebaseStorageReference.put(file, {
      contentType: type,
      contentDisposition: `attachment; filename="${downloadName || name}"`,
    });

    const url = await firebaseStorageReference.getDownloadURL();

    return {
      fileName: name,
      storagePath,
      url,
    };
  } catch (error) {
    console.error("Failed to store file", error);
  }
}

async function replaceEventFeatureImage(image) {
  // TODO enable deletion - If there was an image stored delete it, this will need to make sure it DOES NOT delete the default image
  // if (image.storagePath != null) await firebaseReference.storage().ref().child(previousStoragePath);

  // When the "file" property is set this means the user has selected a new image to replace the existing one with
  const { file: selectedFile } = image;
  if (selectedFile != null) {
    // Store the new image and return it's reference
    return await store("/event-images", selectedFile);
  }

  // When the image object has a URL it references a valid existing image
  if (image.url) {
    return image;
  }

  // In the case that no image exists or is selected, use the default image.
  return DEFAULT_IMAGE;
}

/**
 * Uploads selected files for documents and transforms the data model to the firestore/domain representation
 * @param value The form values for a document {{name, file: { selectedFile, name, storagePath, url }}}
 * @return {Promise<{fileName: string, name: string, storagePath: string, url: string}>}
 */
async function replaceEventDocument(value) {
  const {
    name,
    file: { selectedFile, name: fileName, ...fileRest },
  } = value || {};

  // If a file was selected for upload
  if (selectedFile != null) {
    return {
      name,
      ...(await store(
        "/event-documents",
        selectedFile,
        name + "." + parseFileExtension(selectedFile.name)
      )),
    };
  }
  return {
    name,
    fileName,
    ...fileRest,
  };
}

export function useEventSaver(user) {
  return promiseMutation(async (event) => {
    const { id, image, documents, ...data } = event;

    const now = new Date();
    const documentData = firebaseSafe({
      ...data,
      image: await replaceEventFeatureImage(image),
      documents: await Promise.all(documents.map(replaceEventDocument)),
      updatedAt: now,
      updatedBy: user.id,
    });

    return id == null
      ? firebaseReference
          .firestore()
          .collection("events")
          .add({
            ...documentData,
            createdAt: now,
            createdBy: user.id,
          })
          .then((created) => ({
            ...documentData,
            id: created.id,
          }))
      : firebaseReference
          .firestore()
          .doc(`events/${id}`)
          .set(documentData)
          .then(() => ({
            ...documentData,
            id,
          }));
  }).build()();
}
