import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { isBlank } from "@sempra-event-registration/common";

function RemoveAdminModal({ adminUser, open, onAccept, onClose }) {
  const [submitting, setSubmitting] = useState(false);
  const { firstName, lastName, email, id } = adminUser;
  const displayName =
    !isBlank(firstName) && !isBlank(lastName)
      ? `${firstName} ${lastName}`
      : email || id;

  return (
    <Modal
      size="tiny"
      dimmer="blurring"
      centered={false}
      onClose={onClose}
      open={open}
    >
      <Modal.Header>Remove {displayName}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Are you sure you want to remove <strong>{displayName}</strong> from
            the list of admin-level users? This will revoke their privileges to
            create and edit events.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          loading={submitting}
          onClick={() => {
            onAccept(adminUser, { setSubmitting });
          }}
        >
          Remove
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default RemoveAdminModal;
