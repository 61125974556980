import {
  companyShortNameByCompanyCode,
  isBlank,
  isEmail,
} from "@sempra-event-registration/common";

const emailDomainToInternalResource = {
  "semprautilities.com": {
    companyCode: "sempra",
    resourceType: "employee",
  },
  "sempra.com": {
    companyCode: "sempra",
    resourceType: "employee",
  },
  "seucontractor.com": {
    companyCode: "sempra",
    resourceType: "contractor",
  },
  "sdge.com": {
    companyCode: "sdge",
    resourceType: "employee",
  },
  "sdgecontractor.com": {
    companyCode: "sdge",
    resourceType: "contractor",
  },
  "socalgas.com": {
    companyCode: "scg",
    resourceType: "employee",
  },
  "scgcontractor.com": {
    companyCode: "scg",
    resourceType: "contractor",
  },
};

function emailDomainOf(email) {
  return (email || "").split("@")[1].toLowerCase();
}

export function emailInAudience(audience) {
  return (email) => {
    if (isBlank(email) || !isEmail(email)) {
      return;
    }

    const { companyCode, resourceType } = emailDomainToInternalResource[
      emailDomainOf(email) || ""
    ] || { resourceType: "employee" };

    if (companyCode == null) {
      // This email is external
      if (!audience.userType.external) {
        return "This email does not belong to someone in this organization";
      }
    } else {
      // This email is internal
      if (!audience.userType.internal) {
        return "This email belongs to someone in this organization";
      }
      if (
        !audience.company[companyCode] ||
        !audience.resourceType[resourceType]
      ) {
        const resourceText = `${companyShortNameByCompanyCode[companyCode]} ${
          {
            employee: "employees",
            contractor: "contractors",
          }[resourceType]
        }`;
        return `${resourceText} are not included in the event audience`;
      }
    }
  };
}
