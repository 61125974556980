import {
  comparing,
  composeComparators,
  dateComparator,
  stringComparator,
} from "@sempra-event-registration/common";

/**
 * Get the number of registrants enrolled for each workshop of the event.
 *
 * @param registrants {Array} The collection of registered users from the registrants collection of an event
 * @returns {{name: string, startTime: Date, id: string, count: number}} A collection of workshops, their start times and the number of registrants
 * enrolled in each workshop
 */
export function groupRegistrantsByWorkshop(registrants, event) {
  const { workshops = [] } = event;
  return workshops
    .map((workshop) => ({
      id: workshop.id,
      name: workshop.name,
      startTime: workshop.startTime,
      facilitator: workshop.facilitator,
      count: registrants.filter((x) =>
        (x.workshops || []).some((y) => y.id === workshop.id)
      ).length,
    }))
    .sort(
      composeComparators(
        comparing(stringComparator, (x) => x.name),
        comparing(dateComparator, (x) => x.startTime)
      )
    );
}
