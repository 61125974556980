import React, { createRef, useState, useEffect } from "react";
import { convertFromRaw, EditorState } from "draft-js";
import { Ref } from "semantic-ui-react";
import {
  eventToEventTimeStatus,
  useLoadedUser,
} from "@sempra-event-registration/common";
import EventForm from "../../component/EventForm/EventForm";
import EventFormNavigationMenu from "../../component/EventForm/navigation/EventFormNavigationMenu/EventFormNavigationMenu";
import EventFormControls from "../../component/EventForm/control/EventFormControls/EventFormControls";
import {
  eventToFormValues,
  formValuesToEvent,
  customMessagesToFormValues,
} from "../../support/eventForms";
import EventFormSection from "../../component/EventForm/section/EventFormSection/EventFormSection";
import { eventCopy } from "../../support/eventCopy";
import { getEventFormSections } from "../../support/eventFormSections";
import { useEventSaver } from "../../hook/useEventSaver";
import { PageHeader } from "../../../../../shared/ui";
import { useSearchRestoringHistory } from "../../../../../shared/router";
import toastNotification from "../../../../../shared/toastNotification/toastNotification";
import classes from "./EventFormPage.module.css";
import useDefaultMessages from "../../hook/useDefaultMessages";
import cloneDeep from "lodash.clonedeep";

function EventFormPage({ event, sourceEvent }) {
  const history = useSearchRestoringHistory();
  const user = useLoadedUser();
  // TODO saveEvent and saveEventStatus are the same variable, condense to only use one.
  const [saveEvent, savedEventResource] = useEventSaver(user);
  const [saveEventStatus, savedEventStatusResource] = useEventSaver(user);
  const [currentSection, setCurrentSection] = useState("event-details"); // topmost common section
  const [triedToPublish, setTriedToPublish] = useState(false); // This is for validation on 'description'
  const [description, setDescription] = useState(
    event.description
      ? EditorState.createWithContent(convertFromRaw(event.description))
      : EditorState.createEmpty()
  );
  const [additionalDirections, setAdditionalDirections] = useState(
    event.location?.additionalDirections
      ? EditorState.createWithContent(
          convertFromRaw(event.location?.additionalDirections)
        )
      : EditorState.createEmpty()
  );
  const defaultMessages = useDefaultMessages();
  const [customMessages, setCustomMessages] = useState(
    event.customMessages ? customMessagesToFormValues(event.customMessages) : {}
  );

  useEffect(() => {
    if (Object.keys(defaultMessages).length > 0) {
      setCustomMessages(cloneDeep({ ...defaultMessages, ...customMessages }));
    }
  }, [defaultMessages]);

  const pageRef = createRef();

  const editing = event.id != null;

  const readonly =
    ["complete", "cancelled"].includes(eventToEventTimeStatus(event)) ||
    !(event.createdBy === user.id || user.roles.includes("super_user"));

  const saving = savedEventResource.status === "loading";
  const savingStatus = savedEventStatusResource.status === "loading";

  // If a source event is declared in the page query,
  // use its values to fill in the form
  const defaultValues = eventToFormValues(
    sourceEvent.id != null ? eventCopy(sourceEvent) : event
  );

  async function onSave(values, reset) {
    saveEvent({
      ...event,
      ...formValuesToEvent({
        ...values,
        description,
        additionalDirections,
        customMessages,
      }),
    })
      .then((savedEvent) => {
        console.log("savedEvent", savedEvent);
        toastNotification({
          title: "SAVED SUCCESSFULLY",
          message: `Event: ${savedEvent.name}`,
        });
        if (event.id == null) {
          history.replace(`/events/${savedEvent.id}`);
        }
        reset(eventToFormValues(savedEvent));
      })
      .catch((error) => {
        toastNotification({
          title: "ERROR WHILE SAVING",
          message: "There was an error while saving.",
          type: "danger",
        });
        console.error("Failed to save event", error);
      });
  }

  async function onPublish(values, reset) {
    saveEventStatus({
      ...event,
      ...formValuesToEvent({
        ...values,
        description,
        additionalDirections,
        customMessages,
      }),
      status: "published",
    })
      .then((savedEvent) => {
        reset(eventToFormValues(savedEvent));
        toastNotification({
          title: "PUBLISHED SUCCESSFULLY",
          message: `Event: ${savedEvent.name}`,
        });
      })
      .catch((error) => {
        toastNotification({
          title: "ERROR WHILE PUBLISHING",
          message: "There was an error while publishing",
          type: "danger",
        });
        console.error("Failed to publish event", error);
      });
  }

  async function onCancel(values) {
    saveEventStatus({
      ...event,
      ...formValuesToEvent(values),
      status: "cancelled",
    })
      .then((savedEvent) => {
        toastNotification({
          title: "CANCELLED SUCCESFULLY",
          message: `Event: ${savedEvent.name}`,
        });
      })
      .catch((error) => {
        toastNotification({
          title: "ERROR WHILE CANCELLING",
          message: "There was an error while cancelling.",
          type: "danger",
        });
        console.error("Failed to cancel event", error);
      });
  }

  const sectionsForType = getEventFormSections(event);

  /**
   * Create an object describing all the header elements as React refs, so they can be used as references to the DOM
   * elements for the "scroll into view" feature of the {@link EventFormNavigationMenu}.
   */
  const sectionRefById = sectionsForType.reduce((byId, section) => {
    byId[section.id] = createRef();
    return byId;
  }, {});

  /**
   * Scroll to the section of the form that matches the navigation item that was clicked.
   * <p>
   *     This function is passed to the {@link EventFormNavigationMenu} and used to navigate the user to the section of
   *     the form they want to see.
   * </p>
   * @param event The event object passed in from the onClick event
   * @param id The identifier of the element
   */
  function scrollToSection(event, id) {
    event.preventDefault();

    const sectionLocation = sectionRefById[id].current;
    sectionLocation.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    setCurrentSection(id);
  }

  function sectionProps(id) {
    switch (id) {
      case "event-details":
        return { description, setDescription, triedToPublish };
      case "location":
        return { additionalDirections, setAdditionalDirections };
      case "messages":
        return {
          customMessages,
          setCustomMessages,
          defaultMessages,
          triedToPublish,
        };
      default:
        return {};
    }
  }

  return (
    <Ref innerRef={pageRef}>
      <EventForm {...{ defaultValues, readonly }}>
        <PageHeader stickyRef={pageRef}>
          <EventFormControls
            {...{
              setTriedToPublish,
              description,
              additionalDirections,
              event,
              readonly,
              editing,
              saving,
              defaultValues,
              savingStatus,
              onSave,
              onPublish,
              onCancel,
            }}
          />
        </PageHeader>
        <div className={classes.bottomGrid}>
          <EventFormNavigationMenu
            eventFormSections={sectionsForType}
            currentSection={currentSection}
            onMenuItemClick={scrollToSection}
          />
          <div>
            {sectionsForType.map((section) => (
              <EventFormSection
                {...{ section, readonly, event, ...sectionProps(section.id) }}
                key={section.id}
                sectionRef={sectionRefById[section.id]}
                editing={editing}
              />
            ))}
          </div>
        </div>
      </EventForm>
    </Ref>
  );
}

export default EventFormPage;
