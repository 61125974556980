import React from "react";
import { Button } from "semantic-ui-react";
import classes from "./IconButton.module.css";

function IconButton({ className, ...rest }) {
  return (
    <Button className={`${classes.iconButton} ${className || ""}`} {...rest} />
  );
}

export default IconButton;
