import { GOOGLE_CALLBACK_NAME } from "../constants/google";
import { firebaseReference } from "@sempra-event-registration/common";

export default function addGoogleMapsAPIScript() {
  const src = `https://maps.googleapis.com/maps/api/js?key=${firebaseReference.apps[0].options.apiKey}&libraries=places&callback=${GOOGLE_CALLBACK_NAME}`;

  if (!window.google) {
    const script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
  }
}
