import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import NumberInput from "../NumberInput/NumberInput";

function Input({ value, onChange, type, ...rest }) {
  if (type === "hidden") {
    return <input {...{ value, onChange, type, ...rest }} />;
  }
  if (type === "text") {
    return (
      <Form.Input
        value={value || ""}
        onChange={(event, { value }) => {
          onChange(value);
        }}
        {...{ type, ...rest }}
      />
    );
  }
  return <NumberInput value={value || ""} {...{ type, onChange, ...rest }} />;
}

Input.propTypes = {
  type: PropTypes.oneOf(["hidden", "text", "number", "usd", "phone", "zipCode"])
    .isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  type: "text",
};

export default Input;
