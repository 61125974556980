import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { Sticky } from "semantic-ui-react";
import classes from "./PageHeader.module.css";

function PageHeader({ stickyRef, children, ...rest }) {
  return (
    <Sticky context={stickyRef}>
      <div className={classes.header} {...rest}>
        <Breadcrumbs />
        {children}
      </div>
    </Sticky>
  );
}

export default PageHeader;
