import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { isBlank } from "@sempra-event-registration/common";

function RemoveRegistrationModal({
  event,
  registration,
  onAccept,
  onClose,
  ...rest
}) {
  const [submitting, setSubmitting] = useState(false);

  const fullName = [registration.firstName, registration.lastName]
    .filter((x) => x != null)
    .join(" ");

  const displayName = !isBlank(fullName)
    ? `${fullName} (${registration.email})`
    : registration.email;

  return (
    <Modal
      size="tiny"
      dimmer="blurring"
      centered={false}
      onClose={onClose}
      {...rest}
    >
      <Modal.Header>
        Remove {registration.status === "invited" ? "Invitee" : "Registrant"}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Are you sure you want to remove <strong>{displayName}</strong> from{" "}
            <strong>{event.name}</strong>?
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          loading={submitting}
          onClick={() => {
            onAccept(registration, { setSubmitting });
          }}
        >
          Remove
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default RemoveRegistrationModal;
