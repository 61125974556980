import React, { useState } from "react";
import styles from "./EventAudience.module.css";
import { Checkbox, Input } from "../../../../../../../shared/react-hook-form";

function EventAudience({ control, disabled, audience, watch }) {
  const [error, setError] = useState(undefined);
  const selectedAtLeastOneAudience = watch([
    "audience.company.sempra",
    "audience.company.sdge",
    "audience.company.scg",
  ]);

  const requiredCheckbox = (selectedAtLeastOneAudience) => {
    const selectedAudience = Object.values(selectedAtLeastOneAudience).includes(
      true
    );
    if (!selectedAudience) {
      setError("You must select at least one company");
    } else {
      setError(undefined);
    }
    return error;
  };

  return (
    <div className={`field ${disabled ? "disabled" : ""}`}>
      <label>Who's Invited?</label>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.row}>
        <Checkbox
          control={control}
          name={`audience.company.sempra`}
          label="Sempra"
          rules={{
            validate: (value) =>
              requiredCheckbox({
                ...selectedAtLeastOneAudience,
                "audience.company.sempra": value,
              }),
          }}
        />
        <Checkbox
          control={control}
          name={`audience.company.sdge`}
          label="SDG&E"
          rules={{
            validate: (value) =>
              requiredCheckbox({
                ...selectedAtLeastOneAudience,
                "audience.company.sdge": value,
              }),
          }}
        />
        <Checkbox
          control={control}
          name={`audience.company.scg`}
          label="SoCalGas employees"
          disabled={disabled}
          rules={{
            validate: (value) =>
              requiredCheckbox({
                ...selectedAtLeastOneAudience,
                "audience.company.scg": value,
              }),
          }}
        />
      </div>
      <div className={styles.row}>
        <Checkbox
          name={`audience.resourceType.contractor`}
          control={control}
          label="Include Contractors"
          disabled={disabled}
        />
      </div>

      {/* need to keep these fields in the form so they are saved */}
      <Input
        name={`audience.userType.external`}
        control={control}
        type="hidden"
      />
      <Input
        name={`audience.userType.internal`}
        control={control}
        type="hidden"
      />
      <Input
        name={`audience.resourceType.employee`}
        control={control}
        type="hidden"
      />
    </div>
  );
}

export default EventAudience;
