import React from "react";
import EventContactName from "../../field/EventContactName/EventContactName";
import EventContactEmail from "../../field/EventContactEmail/EventContactEmail";
import EventContactPhone from "../../field/EventContactPhone/EventContactPhone";
import EventNotifyContact from "../../field/EventNotifyContact/EventNotifyContact";
import FieldContainer from "../../component/FieldContainer/FieldContainer";

function EventContactsSection({ fieldProps }) {
  return (
    <FieldContainer>
      <EventContactName {...fieldProps} />
      <EventContactEmail {...fieldProps} />
      <EventContactPhone {...fieldProps} />
      <EventNotifyContact {...fieldProps} />
    </FieldContainer>
  );
}

export default EventContactsSection;
