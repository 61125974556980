import React from "react";
import { Checkbox } from "../../../../../../../shared/react-hook-form";

function EventOptions({ control, disabled, type }) {
  const fieldProps = { control, disabled };
  return (
    <div className={`field ${disabled ? "disabled" : ""}`}>
      <label>Options</label>
      <div>
        {type === "sdge_external" && (
          <>
            <Checkbox
              {...fieldProps}
              name="invitationOnly"
              label="Invitation only"
            />
            <Checkbox
              {...fieldProps}
              name="askForCompanyContact"
              label="Ask for company contact"
            />
          </>
        )}
        {type === "internal" && (
          <>
            <Checkbox
              {...fieldProps}
              name="askIfRepresentedByUnion"
              label="Ask if represented by a union"
            />
            <Checkbox
              {...fieldProps}
              name="askIfFirstEvent"
              label="Ask if first event"
            />
          </>
        )}
        <Checkbox
          {...fieldProps}
          name="askForReleaseWaiver"
          label="Ask for release waiver"
        />
      </div>
    </div>
  );
}

export default EventOptions;
