import React from "react";
import { Icon, Message } from "semantic-ui-react";
import { SemanticDataTable } from "../../../../../../../shared/ui";
import styles from "../../EventReportsPage.module.css";

function ReportDisplay({ report, ...rest }) {
  return (
    <div {...rest}>
      <h2>{report.title}</h2>
      {report.rows.length === 0 ? (
        <Message icon>
          <Icon name="info" className={styles.smallIcon} />
          There is currently no one registered for this event.
        </Message>
      ) : (
        <>
          <SemanticDataTable
            columns={report.columns}
            rows={report.rows}
            singleLine
            striped
            paginationEnabled={false}
            csvDownloadEnabled
            csvFileName={`${report.fileName}.csv`}
            defaultSort={report.defaultSort}
            searchEnabled={false}
          />
          {report && (
            <Message icon>
              <Icon name="info" className={styles.smallIcon} />
              Only registered users are counted in this report.
            </Message>
          )}
        </>
      )}
    </div>
  );
}

export default ReportDisplay;
