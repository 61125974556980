import React from "react";
import { Dropdown, Button, Icon } from "semantic-ui-react";

function CreateEventButton({ options, onClick }) {
  return options.length > 1 ? (
    <Dropdown
      text="Create"
      icon="plus"
      floating
      labeled
      button
      pointing="top right"
      className="icon primary"
    >
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            key={option.value}
            value={option.value}
            onClick={() => onClick(option.value)}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <Button
      primary
      icon
      labelPosition="left"
      onClick={() => onClick(options[0].value)}
    >
      <Icon name="plus" />
      Create Event
    </Button>
  );
}

export default CreateEventButton;
