import React from "react";
import { Form } from "semantic-ui-react";

// TODO move to shared/form (this is effectively semantic-form as it has semantic-ui specific code)
/**
 * Use this when the semantic Form.Field requires a custom non-semantic input
 */
function FormField({ children, label, error, ...rest }) {
  return (
    <Form.Field error={error != null} {...rest}>
      {label && <label>{label}</label>}
      <div className="ui input">{children}</div>
      {error && (
        <div
          className={`ui pointing ${error.pointing || ""} prompt label`}
          role="alert"
          aria-atomic="true"
        >
          {error.content}
        </div>
      )}
    </Form.Field>
  );
}

export default FormField;
