import React from "react";
import { Button } from "semantic-ui-react";
import styles from "../RichTextEditor.module.css";

function EmphasisButtonGroup({ editorState, onButtonClick }) {
  const emphasisStyles = [
    { label: "Bold", style: "BOLD", icon: "bold" },
    { label: "Italic", style: "ITALIC", icon: "italic" },
    { label: "Underline", style: "UNDERLINE", icon: "underline" },
  ];

  const currentStyle = editorState.getCurrentInlineStyle();
  const buttons = emphasisStyles.map((emphasis) => {
    return {
      key: emphasis.icon,
      icon: emphasis.icon,
      active: currentStyle.has(emphasis.style),
      onClick: (e) => onButtonClick(e, emphasis.style),
      className: `${styles.noBorder} ${
        currentStyle.has(emphasis.style) ? styles.activeButtonRoundedBorder : ""
      }`,
    };
  });

  return <Button.Group basic buttons={buttons} className={styles.noBorder} />;
}

export default EmphasisButtonGroup;
