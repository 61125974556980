import React from "react";
import classes from "./AccessDenied.module.css";

function AccessDenied() {
  return (
    <div className={classes.container}>
      <h1>Access Denied</h1>
      <p>
        Please contact your event admin supervisor or "super admin" and request
        permission to manage events.
      </p>
    </div>
  );
}

export default AccessDenied;
