import { useRemoteConfig, map } from "@sempra-event-registration/common";
import { isRunningLocally } from "../development";

const defaultInternalRegistrationBaseUrl = "http://localhost:3001";
const defaultExternalRegistrationBaseUrl = "http://localhost:3002";

export function useAppConfig() {
  const runningLocally = isRunningLocally();
  return map(useRemoteConfig(), (remoteConfig) => ({
    sandboxEnabled: remoteConfig
      .getValue("sandboxEnabled", "false")
      .asBoolean(),
    externalRegistrationBaseUrl: runningLocally
      ? defaultExternalRegistrationBaseUrl
      : remoteConfig
          .getValue(
            "externalRegistrationBaseUrl",
            defaultExternalRegistrationBaseUrl
          )
          .asString(),
    internalRegistrationBaseUrl: runningLocally
      ? defaultInternalRegistrationBaseUrl
      : remoteConfig
          .getValue(
            "internalRegistrationBaseUrl",
            defaultInternalRegistrationBaseUrl
          )
          .asString(),
  }));
}
