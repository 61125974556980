export const adminGroupsToDisplayNamesMap = {
  c_and_i_services: "C&I Services",
  clean_transportation: "Clean Transportation",
  community_relations: "Community Relations",
  customer_experience: "Customer Experience",
  customer_programs: "Customer Programs",
  energy_showcase: "Energy Showcase",
  human_resources: "Human Resources",
  information_technology: "Information Technology",
  lean_in: "Lean In",
  public_affairs: "Public Affairs",
  sys_admin: "SysAdmin",
};

export const adminGroups = [
  "c_and_i_services",
  "clean_transportation",
  "community_relations",
  "customer_experience",
  "customer_programs",
  "energy_showcase",
  "human_resources",
  "information_technology",
  "lean_in",
  "public_affairs",
  "sys_admin",
];
