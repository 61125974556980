import React, { createRef } from "react";
import { Button, Icon } from "semantic-ui-react";
import classes from "./FileSelect.module.css";

function FileSelect({ value, onChange, disabled, children, ...rest }) {
  const { name } = value || {};
  const fileInputRef = createRef();
  function onUploadButtonClick() {
    fileInputRef.current.click();
  }
  function onFileInputChange(event) {
    const [file] = event.target.files;
    if (file != null) {
      onChange(file);
    }
  }
  return (
    <>
      <Button
        className={classes.button}
        title={name}
        primary={name == null}
        inverted={name == null}
        basic={name != null}
        color={name != null ? "black" : "grey"}
        disabled={disabled}
        onClick={onUploadButtonClick}
        {...rest}
      >
        <Icon name="upload" />
        <span className={classes.label}>{name || children || "Upload"}</span>
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        hidden
        onChange={onFileInputChange}
      />
    </>
  );
}

export default FileSelect;
