import React, { useContext } from "react";
import { parse } from "query-string";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  useLoadedUser,
  compose,
  Error,
} from "@sempra-event-registration/common";
import { useEvent } from "./hook/useEvent";
import { EventContext } from "./context/EventContext";
import EventFormPage from "./page/EventFormPage/EventFormPage";
import EventRegistrationPage from "./page/EventRegistrationPage/EventRegistrationPage";
import EventReportsPage from "./page/EventReportsPage/EventReportsPage";
import Loader from "../../../shared/ui/component/Loader/Loader";

function Routes({ user, path }) {
  const { source: sourceId } = parse(window.location.search);
  const resources = compose(useContext(EventContext), useEvent(sourceId, user));

  if (resources.status === "loading" || resources.status === "empty") {
    return <Loader />;
  }
  if (resources.status === "error") {
    return <Error message={resources.error.message} />;
  }

  const [event, sourceEvent] = resources.value;

  return (
    <Switch>
      <Route exact path={path}>
        <EventFormPage {...{ event, sourceEvent }} />
      </Route>
      <Route
        exact
        path={`${path}/registration`}
        component={EventRegistrationPage}
      />
      <Route exact path={`${path}/reports`} component={EventReportsPage} />
      <Redirect to={path} />
    </Switch>
  );
}

function EventPage({ match }) {
  const user = useLoadedUser();
  const {
    path,
    params: { eventId },
  } = useRouteMatch();
  const event = useEvent(eventId, user);

  return (
    <EventContext.Provider value={event}>
      <Routes {...{ user, path }} />
    </EventContext.Provider>
  );
}

export default EventPage;
