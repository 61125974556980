import Papa from "papaparse";

export const csvGenerator = function (data, fileName) {
  const cleanedData = cleanData(data);

  const parsed = Papa.unparse(cleanedData, { quotes: true });

  downloadCSV(parsed, fileName);
};

const downloadCSV = function (parsed, fileName) {
  var blob = new Blob([parsed]);
  if (window.navigator.msSaveOrOpenBlob) {
    // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    window.navigator.msSaveBlob(
      blob,
      fileName || "sempra-event-registration-report.csv"
    );
  } else {
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
    a.download = fileName || "sempra-event-registration-report.csv";
    document.body.appendChild(a);
    a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    document.body.removeChild(a);
  }
};

const cleanData = function (data) {
  const newData = data.map((row) => {
    let cleanedRowData = [];
    Object.entries(row).forEach(([fieldName, value]) => {
      if (isDataObject(value)) {
        const cleaned = cleanNestedObject(fieldName, value);
        cleanedRowData = cleanedRowData.concat(cleaned);
        return;
      }
      cleanedRowData.push([fieldName, value]);
    });
    return Object.fromEntries(cleanedRowData);
  });
  return newData;
};

// Note: Recursive function that flattens any nested object
const cleanNestedObject = function (fieldName, value) {
  let cleanedNestedObj = [];

  Object.entries(value).forEach(([nestedName, nestedValue]) => {
    if (isDataObject(nestedValue)) {
      cleanedNestedObj = cleanedNestedObj.concat(
        cleanNestedObject(`${fieldName}.${nestedName}`, nestedValue)
      );
      return;
    }
    cleanedNestedObj.push([`${fieldName}.${nestedName}`, nestedValue]);
  });
  return cleanedNestedObj;
};

// Note: determining if you have a obj or something else crazy is hard...
const isDataObject = function (obj) {
  if (
    typeof obj === "object" &&
    obj instanceof Object &&
    obj !== null &&
    !(obj instanceof Date)
  ) {
    return true;
  }

  return false;
};
