import React from "react";
import { Input } from "../../../../../../../../../shared/react-hook-form";
import { requiredNotBlank } from "@sempra-event-registration/common";
import {
  selectError,
  FileSelect,
} from "../../../../../../../../../shared/form";
import classes from "./EventDocument.module.css";
import { Controller } from "react-hook-form";
import { FormField } from "../../../../../../../../../shared/semantic-form";

function EventDocument({ index, field, namePrefix, errors, ...fieldProps }) {
  // Get the maximum file size in bytes.
  // NOTE: The storage.rules for the event-documents bucket enforce the maximum file size.  If the storage.rules for the
  // event-documents bucket changes, the maxFileSizeInBytes should be updated to match.
  const maxFileSizeInBytes = 10 * 1024 * 1024;
  const { disabled } = fieldProps;

  return (
    <span className={classes.grid}>
      <Input
        className={classes.name}
        name={namePrefix + "name"}
        label={`Document ${index + 1}`}
        error={selectError(() => errors.name)}
        rules={{
          validate: requiredNotBlank("Required"),
        }}
        defaultValue={field.name || ""}
        {...fieldProps}
      />
      <Controller
        name={namePrefix + "file"}
        rules={{
          required: "Required",
          validate: {
            lessThanTenMb: (value) => {
              // If the value has a selectedFile property, then we know the file has not been uploaded yet.  We need to
              // do this check because when this validator is triggered on a save/publish, the validator will evaluate
              // files that have been already uploaded (which we don't need to validate, since they're already uploaded).
              // Documents that have already been uploaded will not have a "selectedFile" property.
              const fileSizeInBytes = value?.selectedFile?.size || 0;

              return fileSizeInBytes < maxFileSizeInBytes
                ? undefined
                : "File must be less than 10MB";
            },
          },
        }}
        defaultValue={field.file || ""}
        render={({ value, onChange }) => (
          <FormField
            label={<>&nbsp;</>}
            disabled={disabled}
            error={selectError(() => errors.file)}
          >
            <FileSelect
              disabled={disabled}
              value={value.selectedFile ? value.selectedFile : value}
              onChange={(selectedFile) => {
                onChange({ ...value, selectedFile });
              }}
            />
          </FormField>
        )}
      />
      <Controller
        name={namePrefix + "id"}
        defaultValue={field.id || ""}
        render={(props) => <input type="hidden" {...props} />}
      />
    </span>
  );
}

export default EventDocument;
