import React, { useState } from "react";
import { Message, Modal, Button, Icon } from "semantic-ui-react";
import classes from "./InviteesModal.module.css";
import { map, promiseQuery } from "@sempra-event-registration/common";
import { readFile } from "../../support/files";
import { inviteeCsvParser } from "../../support/inviteeForms";
import InviteeTable from "../InviteeTable/InviteeTable";
import { FileSelect } from "../../../../../../../shared/form";

const useInvitees = promiseQuery((file) =>
  readFile(file)
    .then(inviteeCsvParser)
    // TODO collate with data from findUsers
    .then((invitees) => invitees)
)
  .condition((file) => file != null)
  .build();

function inviteesToRows(validator) {
  return (invitees) =>
    invitees
      // In case of duplicate rows take the latest row
      .reduce(
        (data, invitee, index) => {
          const lineNumber = index + 1;
          const row = {
            lineNumber,
            ...invitee,
          };
          const inviteeIndex = data.inviteeIndexByEmail[invitee.email];
          if (inviteeIndex == null) {
            data.inviteeIndexByEmail[invitee.email] =
              data.uniqueInvitees.length;
            data.uniqueInvitees.push(row);
          } else {
            data.uniqueInvitees[inviteeIndex] = row;
          }
          return data;
        },
        {
          inviteeIndexByEmail: {},
          uniqueInvitees: [],
        }
      )
      .uniqueInvitees // Validate rows and sort them into valid and invalid rows
      .reduce(
        (data, invitee) => {
          const row = { ...invitee };
          const errors = validator(invitee);
          if (errors.length > 0) {
            row.errors = errors;
            data.invalid.push(row);
          } else {
            data.valid.push(row);
          }
          return data;
        },
        {
          valid: [],
          invalid: [],
        }
      );
}

function InviteesModal({ inviteeValidator, onClose, onAccept, ...rest }) {
  const [file, setFile] = useState();
  const [validFile, setValidFile] = useState(true);
  const [skipInvalidInvitees, setSkipInvalidInvitees] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const rows = map(useInvitees(file), inviteesToRows(inviteeValidator));

  return (
    <Modal
      className={classes.widthMaxContent}
      dimmer="blurring"
      centered={false}
      onClose={onClose}
      {...rest}
    >
      <Modal.Header>Add Invitees</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {file == null && <p>Import invitees by uploading a CSV file</p>}
          {validFile === false && (
            <Message
              style={{ display: "flex" }}
              error
              content="Invalid file type. Please ensure you are uploading a csv file."
            />
          )}
          <div className={classes.uploadControls}>
            <FileSelect
              className={classes.fileButton}
              loading={rows.status === "loading"}
              value={file}
              onChange={(file) => {
                if (
                  file !== undefined &&
                  file.name.split(".").pop() !== "csv"
                ) {
                  setValidFile(false);
                } else {
                  setFile(file);
                  setValidFile(true);
                  setSkipInvalidInvitees(false);
                }
              }}
            >
              Upload CSV
            </FileSelect>
            {file == null && (
              <a tabIndex="0" href="/example-invitee-csv.csv" download>
                <Icon size="large" name="file alternate outline" />
                Example Invitee CSV
              </a>
            )}
          </div>

          {rows.status === "error" && (
            <Message
              style={{ display: "flex" }}
              error
              content="Failed to parse invitees from the CSV file provided."
            />
          )}
          {rows.status === "loaded" && (
            <>
              {rows.value.invalid.length > 0 && !skipInvalidInvitees ? (
                <>
                  <Message
                    style={{ display: "flex" }}
                    error
                    content="Some invitees need your attention"
                  />
                  <div className={classes.tableContainer}>
                    <InviteeTable rows={rows.value.invalid} showErrors />
                  </div>
                </>
              ) : (
                <>
                  {rows.value.valid.length === 0 ? (
                    <Message
                      style={{ display: "flex" }}
                      content="All valid invitees in this file are already invited or registered."
                    />
                  ) : (
                    <InviteeTable rows={rows.value.valid} />
                  )}
                </>
              )}
            </>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div className={classes.controls}>
          {rows.status === "loaded" && (
            <>
              {rows.value.invalid.length > 0 && !skipInvalidInvitees ? (
                <Button
                  primary
                  onClick={() => {
                    setSkipInvalidInvitees(true);
                  }}
                >
                  Skip
                  <Icon name="arrow right" />
                </Button>
              ) : (
                <>
                  {rows.value.valid.length > 0 && (
                    <Button
                      primary
                      loading={submitting}
                      onClick={() => {
                        onAccept(rows.value.valid, { setSubmitting });
                      }}
                    >
                      <Icon name="send" />
                      Send Invitations
                    </Button>
                  )}
                </>
              )}
            </>
          )}
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default InviteesModal;
