export function readFile(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      resolve(event.target.result);
    };
    fileReader.onerror = (event) => {
      reject(event.target.error);
    };
    fileReader.readAsText(file, "UTF-8");
  });
}
