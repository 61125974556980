import React from "react";
import { Input } from "../../../../../../../shared/react-hook-form";

function EventContactPhone({ control, disabled }) {
  return (
    <Input
      name="contactPhone"
      label="Phone"
      type="phone"
      control={control}
      disabled={disabled}
    />
  );
}

export default EventContactPhone;
