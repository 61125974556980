import React from "react";
import { Dropdown } from "semantic-ui-react";
import styles from "../RichTextEditor.module.css";

function FontSizeSelect({ editorState, onSelectionChange }) {
  const fontSizes = [
    { label: "Paragraph", style: "unstyled" },
    {
      label: "Header 1",
      style: "header-one",
      css: { fontSize: "28px", color: "rgba(0, 0, 0, 0.6)" },
    },
    {
      label: "Header 2",
      style: "header-two",
      css: { fontSize: "24px", color: "rgba(0, 0, 0, 0.6)" },
    },
    {
      label: "Header 3",
      style: "header-three",
      css: { fontSize: "18px", color: "rgba(0, 0, 0, 0.6)" },
    },
    {
      label: "Header 4",
      style: "header-four",
      css: { fontSize: "15px", color: "rgba(0, 0, 0, 0.6)" },
    },
    {
      label: "Header 5",
      style: "header-five",
      css: { fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" },
    },
    {
      label: "Header 6",
      style: "header-six",
      css: { fontSize: "10.72px", color: "rgba(0, 0, 0, 0.6)" },
    },
  ];

  const selection = editorState.getSelection();
  const blockType =
    editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType() || "unstyled";

  const options = fontSizes.map((fontSize) => {
    return {
      key: fontSize.style,
      value: fontSize.style,
      text: fontSize.label,
      style: fontSize.css,
    };
  });

  // The "find" in the value setting ensures that the dropdown always accurately reflects the font size currently in
  // use.  Without this "find", the dropdown will be set to an empty/unselected value when some text is converted to a
  // bulleted lis (or any of the block-style formats).
  return (
    <Dropdown
      selection
      options={options}
      value={fontSizes.find((f) => f.style === blockType)?.style || "unstyled"}
      onChange={onSelectionChange}
      className={`${styles.noBorder} ${styles.fontSizeDropdown}`}
    />
  );
}

export default FontSizeSelect;
