import React from "react";
import { Modal, Button } from "semantic-ui-react";

function DeleteEventModal({ event, onAccept, onClose, ...rest }) {
  const { name } = event;

  return (
    <Modal
      size="tiny"
      dimmer="blurring"
      centered={false}
      onClose={onClose}
      {...rest}
    >
      <Modal.Header>Delete</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Are you sure you want to delete your draft <strong>{name}</strong>?
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            onAccept();
          }}
        >
          Delete
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeleteEventModal;
