import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// Import stylesheets
import "react-notifications-component/dist/theme.css";
import "animate.css";
import "semantic-ui-css/semantic.min.css";
import "draft-js/dist/Draft.css";
import "react-datepicker/dist/react-datepicker.css";
import "@sempra-event-registration/common/dist/index.css";
import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));
