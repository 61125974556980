import React from "react";
import EventGroup from "./component/EventGroup/EventGroup";
import EventGroupOption from "./component/EventGroupOption/EventGroupOption";
import { FieldTree } from "../../../../../../../shared/react-hook-form";

function EventGroups({ ...fieldProps }) {
  const field = {
    name: "groups",
    label: "Group",
    limit: 5,
    render: (props) => <EventGroup {...{ ...fieldProps, ...props }} />,
    empty: () => ({
      name: "",
    }),
    childFields: [
      {
        name: "subgroups",
        label: "Subgroup",
        limit: 5,
        render: (props) => (
          <EventGroupOption {...{ ...fieldProps, ...props }} />
        ),
        empty: () => ({
          subgroup: "",
          capacity: "",
          supervisor: "",
          mailLocation: "",
        }),
      },
    ],
  };

  return <FieldTree {...{ field, ...fieldProps }} />;
}

export default EventGroups;
