import React from "react";
import { absenceStatuses, isBlank } from "@sempra-event-registration/common";
import FormModal from "../FormModal/FormModal";
import RegistrationFormFields from "../RegistrationFormFields/RegistrationFormFields";

function RegistrationModal({
  event,
  registration,
  registrations,
  defaultValues,
  disabled,
  open,
  onClose,
  onAccept,
}) {
  const { status, previousStatus, firstName, lastName, email } = registration;
  const editing = registration.id != null && previousStatus == null;
  const readonly =
    disabled || (absenceStatuses.includes(status) && previousStatus == null);
  const invitee = status === "invited";
  const name =
    !isBlank(firstName) && !isBlank(lastName)
      ? `${firstName} ${lastName}`
      : email || (invitee ? "Invitee" : "Registrant");

  const accept = () =>
    previousStatus
      ? "Register"
      : editing
      ? "Save"
      : invitee
      ? "Invite"
      : "Register";

  const heading = previousStatus
    ? `Register ${name}`
    : readonly || editing
    ? name
    : `Add ${name}`;

  return (
    <FormModal
      {...{
        heading,
        accept,
        editing,
        registration,
        disabled: readonly,
        open,
        defaultValues,
        onClose,
        onAccept,
      }}
    >
      <RegistrationFormFields
        {...{
          event,
          registrations,
          registration,
          disabled: readonly,
          internalResourceSearchEnabled: event.audience.userType.internal,
        }}
      />
    </FormModal>
  );
}

export default RegistrationModal;
