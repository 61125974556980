import { useHistory } from "react-router-dom";
import { isBlank } from "@sempra-event-registration/common";

const lastSearchByPathname = {};

// Interprets the first argument of history.push which can be a string or object
function pathOrPushOptionToPushOptions(pathOrPushOptions) {
  if (typeof pathOrPushOptions === "string") {
    const [pathname, search = ""] = pathOrPushOptions.split("?");
    return { pathname, search };
  }
  return pathOrPushOptions;
}

export function useSearchRestoringHistory() {
  const history = useHistory();
  return Object.assign({}, history, {
    // Proxy all calls the history.push
    // We want to trap when all route changes are made
    // Note: this will not trap <Link> component initiated route changes
    push: (pathOrPushOptions, ...rest) => {
      // Get the current search and store it
      // It is the last search for the current path
      const { location } = window;
      lastSearchByPathname[location.pathname] = location.search;

      // Interpret the history.push parameters
      const pushOptions = pathOrPushOptionToPushOptions(pathOrPushOptions);
      const nextPushOptions = isBlank(pushOptions.search)
        ? // if no explicit search was set on history.push() restore the search for this route
          {
            ...pushOptions,
            search: lastSearchByPathname[pushOptions.pathname] || "",
          }
        : // if an explicit search is set on history.push() use it
          pushOptions;

      // Delegate to original history.push method
      history.push(nextPushOptions, ...rest);
    },
  });
}
