import React from "react";

// Copied from shared-it-appmod-ui "ATMBrandLogo"
// TODO move this SVG to the public dir
function Logo() {
  const color = "white";
  const style = {
    height: "31px",
    width: "auto",
    padding: "0 0.5rem",
  };

  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 482.17334 119.6"
      xmlSpace="preserve"
      id="svg2"
      version="1.1"
    >
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,119.6)" id="g10">
        <g transform="scale(0.1)" id="g12">
          <path
            id="path14"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 3570.46,434.621 c -26.05,0 -45.87,18.547 -45.87,45.863 0,27.313 19.82,45.864 45.87,45.864 26.04,0 45.86,-18.551 45.86,-45.864 0,-27.316 -19.82,-45.863 -45.86,-45.863 z m 0,85.883 c -22.49,0 -39.64,-16.395 -39.64,-40.02 0,-23.632 17.15,-40.019 39.64,-40.019 22.49,0 39.64,16.387 39.64,40.019 0,23.625 -17.15,40.02 -39.64,40.02 z m 8.64,-42.816 11.31,-21.727 h -7.88 l -10.93,21.219 h -10.92 v -21.219 h -6.74 v 50.695 h 20.59 c 9.14,0 16.26,-4.574 16.26,-14.613 0,-7.625 -4.58,-12.703 -11.69,-14.355 z m -4.96,22.996 h -13.46 v -17.153 h 12.7 c 7.24,0 10.67,2.668 10.67,8.512 0,5.715 -3.55,8.641 -9.91,8.641"
          />
          <path
            id="path16"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 647.813,897.004 c 65.597,-129.258 20.636,-243.867 -1.918,-287.793 46.265,53.957 49.335,106.25 49.335,106.25 0,0 8.454,-16.672 11.215,-35.379 23.828,65.504 -1.492,120.379 -1.492,120.379 0,0 56.602,-87.785 -5.039,-165.539 -1.496,12.191 -3.992,21.777 -3.992,21.777 -12.363,-22.969 -38.734,-55.316 -67.473,-77.832 69.852,19.531 101.621,53.961 115.457,99.492 34.973,115.215 -96.093,218.645 -96.093,218.645 v 0"
          />
          <path
            id="path18"
            style={{
              fill: color ?? "#e01049",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 736.176,610.969 c -1.395,1.101 -2.949,0.41 -3.645,-0.328 -1.597,-1.477 -81.308,-86.637 -234.609,-66.168 -50.039,6.632 -93.027,18.254 -127.5,26.906 11.094,22.598 19.121,54.773 -13.84,73.406 -47.168,26.653 -111.348,-19.363 -114.996,-25.508 -1.41,-2.375 0.516,-3.73 2.539,-3.886 8.258,-0.821 71.598,0.898 98.738,-37.348 -67.226,16.629 -75.312,-12.113 -65.425,-41.676 5.855,-17.691 21.078,-50.484 42.988,-82.461 0,0 1.48,-2.011 0.043,-3.808 -0.778,-1.028 -2.039,-1.637 -3.278,-2.051 C 210.859,412.473 131.965,307.445 81.6211,217.457 c -24.2578,46.715 -38.0625,99.773 -38.0625,156 0,188.047 153.5044,340.481 342.7894,340.481 36.34,0 71.328,-5.606 104.16,-16.008 l 0.265,0.781 C 450.586,714.102 406.906,722.574 361.27,722.574 161.73,722.574 0,560.813 0,361.289 0,290.25 20.4688,224.004 55.8906,168.152 30.2188,115.254 16.6602,75.5078 15.6836,72.4336 11.1641,58.5508 10.4414,46.957 19.0391,43.0703 26.6797,39.7188 33.332,44.0586 45.6953,61.0508 84.918,115.012 251.438,362.07 367.129,283.906 c 57.504,-38.855 75.598,-74.883 42.109,-108.539 -17.695,-17.738 -59.906,-42.711 -85.351,-54.754 -39.364,-18.582 -60.672,-22.3005 -57.422,-36.1872 3.027,-12.8164 19.523,-12.7383 85.793,4.418 76.14,19.7422 249.004,64.6912 159.726,211.1562 -40.308,66.199 -81.621,116.238 -109.296,157.301 -0.743,1.07 -0.989,2.133 -0.168,3.351 0.949,1.36 3.437,1.157 3.437,1.157 293.57,21.824 329.356,140.718 330.422,144.695 0.242,0.82 1.23,3.309 -0.203,4.465 v 0"
          />
          <path
            id="path20"
            style={{
              fill: color ?? "#e01049",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="M 695.512,226.133 C 640.207,111.816 522.59,32.9219 386.348,32.9219 289.922,32.9219 202.754,72.5117 140.441,136.223 130.137,123.613 120.559,111.328 111.855,99.9023 176.754,38.0859 264.582,0 361.27,0 512.91,0 642.695,93.3945 696.293,225.805 l -0.781,0.328 v 0"
          />
          <path
            id="path22"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 968.43,465.941 h -9.153 l -12.351,35.704 c -7.785,5.945 -26.543,10.972 -46.227,10.972 -31.117,0 -54.918,-13.265 -54.918,-48.519 0,-30.653 27.91,-48.043 63.614,-62.235 37.062,-14.636 81.007,-37.074 81.007,-94.273 0,-55.82 -41.652,-86.043 -98.855,-86.043 -40.277,0 -72.309,17.855 -81.918,24.715 v 49.429 h 10.98 l 10.067,-38.433 c 11.898,-10.528 35.699,-20.156 63.164,-20.156 40.265,0 63.152,21.976 63.152,59.968 0,27 -17.851,48.047 -61.777,65.903 -40.742,16.476 -82.848,36.597 -82.848,90.152 0,42.559 29.301,75.051 87.879,75.051 32.031,0 54.91,-7.778 68.184,-13.719 v -48.516 0"
          />
          <path
            id="path24"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 1057.66,354.734 c 4.13,38.43 22.43,71.391 50.81,71.391 34.78,0 44.85,-44.387 44.85,-71.391 h -95.66 z m -0.9,-15.105 h 133.63 c 1.83,33.41 -10.98,99.316 -81,99.316 -52.63,0 -90.62,-43.015 -90.62,-107.562 0,-61.781 38.89,-108.918 102.97,-108.918 21.51,0 42.1,5.933 62.24,18.301 l -4.57,10.074 c -19.68,-10.074 -33.4,-11.895 -52.18,-11.895 -43.93,0 -70.02,42.559 -70.47,100.684 v 0"
          />
          <path
            id="path26"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 1576.19,226.586 h -69.1 v 145.086 c 0,27 -5.5,49.418 -31.58,49.418 -16.02,0 -43.94,-10.973 -61.33,-31.57 0,0 0,-19.692 0,-146.454 l 26.08,-7.785 v -8.695 h -60.86 v 145.086 c 0,27 -6.41,49.418 -30.67,49.418 -18.3,0 -47.59,-12.82 -62.69,-31.57 V 241.688 l 25.62,-6.407 v -8.695 h -92.44 v 8.695 l 32.03,7.309 v 171.64 l -37.53,7.313 v 8.695 c 24.26,2.746 40.28,4.586 65.91,3.207 l 5.03,-23.328 c 22.88,18.754 48.97,28.828 73.69,28.828 27.46,0 42.56,-14.648 48.97,-33.867 17.39,16.934 48.5,33.867 75.06,33.867 41.19,0 59.49,-22.89 59.49,-74.156 V 242.59 l 34.32,-7.309 v -8.695 0"
          />
          <path
            id="path28"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 1656.27,379.895 c 10.99,22.894 29.31,40.273 51.73,40.273 28.83,0 45.3,-30.195 45.3,-88.785 0,-56.285 -15.1,-94.281 -59.04,-94.281 -8.24,0 -24.71,1.375 -37.99,9.628 v 133.165 z m -1.37,23.335 c -1.37,11.45 -5.04,25.188 -6.4,30.215 -25.18,2.754 -40.27,-0.461 -64.54,-3.207 v -8.695 l 37.54,-7.313 V 146.953 l -34.32,-7.789 v -8.695 h 103.43 v 9.621 l -34.34,6.406 v 85.121 c 9.16,-5.031 24.27,-9.152 40.28,-9.152 50.34,0 96.12,38.453 96.12,108.465 0,57.195 -19.23,108.015 -73.69,108.015 -22.43,0 -48.52,-12.375 -64.08,-35.715 v 0"
          />
          <path
            id="path30"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 1887.39,242.141 33.87,-6.403 v -9.152 h -102.98 v 9.152 l 34.33,6.852 v 171.64 l -37.52,7.313 v 8.695 c 24.25,2.746 38.89,6.41 64.05,3.668 1.85,-5.957 6.41,-15.566 7.34,-27.449 17.84,18.75 38.44,32.488 68.18,32.488 6.41,0 12.37,-0.461 17.41,-2.297 l -13.29,-28.378 c -2.26,1.375 -9.15,4.589 -18.76,4.589 -13.28,0 -32.5,-4.589 -52.63,-25.632 v -145.086 0"
          />
          <path
            id="path32"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 2109.35,277.379 c -10.53,-25.16 -28.38,-37.063 -45.3,-37.063 -21.07,0 -40.27,16.461 -40.27,44.856 0,35.687 26.52,53.539 58.57,53.539 h 27 v -61.332 z m 66.82,-38.902 -32.04,6.41 c 0,135.457 0,77.343 0,124.488 0,52.168 -35.23,69.57 -72.3,69.57 -27.93,0 -56.75,-10.074 -72.32,-22.422 l 9.62,-41.187 10.06,1.816 2.3,29.746 c 7.78,7.782 25.62,14.192 43.48,14.192 22.41,0 44.38,-11.445 44.38,-44.391 v -22.883 h -15.56 c -50.81,0 -105.71,-15.105 -105.71,-75.519 0,-33.41 30.66,-56.293 63.15,-56.293 21.97,0 44.38,10.066 59.49,32.953 l 8.71,-27.914 c 0,0 24.25,-2.277 56.74,2.73 v 8.704 0"
          />
          <path
            id="path34"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 2475.47,265.48 -11.88,-38.894 h -192.7 v 10.516 l 32.04,6.414 v 265.441 l -32.04,6.875 v 10.516 h 181.24 l 11.91,-38.438 -9.61,-4.121 -14.66,24.719 h -99.76 V 395.012 h 97.02 v -17.86 h -97.02 V 244.434 h 110.75 l 15.09,25.168 9.62,-4.122 v 0"
          />
          <path
            id="path36"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 2725.36,226.586 h -67.3 v 145.535 c 0,37.996 -10.52,49.422 -34.78,49.422 -13.72,0 -43.93,-13.273 -58.57,-31.57 V 241.688 l 25.63,-6.407 v -8.695 h -92.46 v 8.695 l 32.04,7.309 v 171.64 l -37.53,7.313 v 8.695 c 24.27,2.746 40.73,5.961 65.91,3.668 l 5.03,-23.789 c 15.56,13.715 44.38,28.828 70.02,28.828 40.28,0 59.49,-21.523 59.49,-72.781 V 242.59 l 32.52,-7.309 v -8.695 0"
          />
          <path
            id="path38"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 2782.08,354.734 c 4.13,38.43 22.44,71.391 50.81,71.391 34.78,0 44.86,-44.387 44.86,-71.391 h -95.67 z m -0.9,-15.105 h 133.62 c 1.84,33.41 -10.98,99.316 -80.98,99.316 -52.64,0 -90.64,-43.015 -90.64,-107.562 0,-61.781 38.9,-108.918 102.97,-108.918 21.52,0 42.12,5.933 62.25,18.301 l -4.58,10.074 c -19.68,-10.074 -33.4,-11.895 -52.18,-11.895 -43.93,0 -70.02,42.559 -70.46,100.684 v 0"
          />
          <path
            id="path40"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 3010.44,242.141 33.89,-6.403 v -9.152 h -102.98 v 9.152 l 34.31,6.852 v 171.64 l -37.52,7.313 v 8.695 c 24.26,2.746 38.9,6.41 64.08,3.668 1.82,-5.957 6.4,-15.566 7.32,-27.449 17.86,18.75 38.45,32.488 68.19,32.488 6.41,0 12.35,-0.461 17.38,-2.297 l -13.26,-28.378 c -2.3,1.375 -9.15,4.589 -18.77,4.589 -13.26,0 -32.48,-4.589 -52.64,-25.632 v -145.086 0"
          />
          <path
            id="path42"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 3143.61,368.004 c 0,32.488 21.52,55.375 46.68,55.375 25.63,0 45.78,-22.887 45.78,-55.375 0,-42.106 -23.81,-58.121 -45.78,-58.121 -21.04,0 -46.68,16.484 -46.68,58.121 z m -16.02,-48.973 c -13.26,-9.629 -21.97,-24.707 -21.97,-39.363 0,-20.121 11.92,-39.352 36.15,-45.305 -27.44,-6.863 -44.38,-27 -44.38,-48.965 0,-27.003 26.08,-54.46 91.98,-54.46 72.33,0 105.74,42.089 105.74,80.539 0,19.691 -8.7,38.449 -25.64,49.89 -14.65,0 -59.04,0 -91.07,0 -37.53,0 -50.35,13.731 -50.35,29.293 0,7.778 4.12,15.563 9.61,20.121 16.93,-11.886 38,-16.457 52.63,-16.457 56.3,0 80.09,37.059 80.09,72.762 0,18.766 -6.85,35.703 -20.13,48.969 l 37.08,6.855 v 12.367 h -63.18 c -8.68,1.844 -24.23,3.668 -33.86,3.668 -51.72,0 -81,-30.675 -81,-71.859 0,-21.051 7.77,-36.606 18.3,-48.055 z m 130.9,-88.777 c 9.15,-5.488 14.19,-16.031 14.19,-27.461 0,-25.18 -22.43,-55.84 -73.23,-55.84 -50.8,0 -67.28,21.035 -67.28,42.11 0,17.851 12.82,35.703 27.92,41.191 h 98.4 v 0"
          />
          <path
            id="path44"
            style={{
              fill: color ?? "#204394",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            d="m 3343.61,144.648 c 17.85,9.153 36.62,21.524 43.93,41.665 l 16.48,46.226 -67.27,182.59 -30.22,10.078 v 8.699 h 88.78 v -8.699 l -22.41,-7.785 48.05,-141.414 55.85,157.898 h 48.04 v -8.699 l -35.23,-9.602 -80.56,-221.503 c -15.1,-41.196 -27,-51.27 -54.92,-67.747 l -10.52,18.293 v 0"
          />
        </g>
      </g>
    </svg>
  );
}

export default Logo;
