import React from "react";
import { Modal, Button } from "semantic-ui-react";
import classes from "./PublishEventModal.module.css";

function PublishEventModal({ event, onAccept, onClose, ...rest }) {
  const { name } = event;
  return (
    <Modal
      size="tiny"
      dimmer="blurring"
      centered={false}
      onClose={onClose}
      {...rest}
    >
      <Modal.Header>Publish</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Publishing this event will make it available for registration.</p>
          <p>
            Are you sure you are ready to publish <strong>{name}</strong>?
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div className={classes.controls}>
          <Button
            primary
            onClick={() => {
              onAccept();
            }}
          >
            Publish
          </Button>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default PublishEventModal;
