import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import Header from "../../ui/component/Header/Header";
import classes from "../../../App.module.css";
import { firebaseReference } from "@sempra-event-registration/common";
import { Button } from "semantic-ui-react";

const logout = function logout() {
  firebaseReference.auth().signOut();
};

function SignedOut() {
  const history = useHistory();
  useEffect(logout);
  return (
    <>
      <Header />
      <main className={classes.main}>
        <Segment>
          <div>
            Thank you for using Event Registration, please click the Sign In
            button below or Events to sign back in.
          </div>
          <Button
            style={{ marginTop: "15px" }}
            type="button"
            primary
            onClick={() => history.push("/")}
          >
            Sign In
          </Button>
        </Segment>
      </main>
    </>
  );
}

export default SignedOut;
