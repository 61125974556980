import React from "react";
import { selectError } from "../../../../../../../shared/form";
import {
  emailPattern,
  requiredNotBlank,
} from "@sempra-event-registration/common";
import { Input } from "../../../../../../../shared/react-hook-form";

function EventContactEmail({ control, disabled, errors }) {
  return (
    <Input
      name="contactEmail"
      label="Email"
      type="text"
      control={control}
      disabled={disabled}
      rules={{
        validate: requiredNotBlank("Required"),
        pattern: {
          value: emailPattern,
          message: "Please enter a valid email address",
        },
      }}
      error={selectError(() => errors.contactEmail)}
    />
  );
}

export default EventContactEmail;
