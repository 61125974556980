import { isBlank, isEmail } from "@sempra-event-registration/common";
import { csvParser } from "./csvParser";
import { emailInAudience } from "./emailInAudience";
import { reservationStatuses } from "@sempra-event-registration/common/src/event";

export const inviteeCsvParser = csvParser({
  headers: ["email", "firstName", "lastName"],
});

const invitedOrReserved = ["invited", ...reservationStatuses];

export function inviteeValidator(registrations, audience) {
  const audienceValidator = emailInAudience(audience);
  return (invitee) => {
    const { email } = invitee;
    const errors = [];
    if (isBlank(email)) {
      errors.push({
        field: "email",
        message: "Invitee email is required",
      });
    } else if (!isEmail(email)) {
      errors.push({
        field: "email",
        message: `Invalid email address "${email}"`,
      });
    } else if (audienceValidator(email) != null) {
      errors.push({
        field: "email",
        message: audienceValidator(email),
      });
    } else {
      const registration = registrations.find(
        (x) =>
          x.email.toLowerCase() === email.toLowerCase() &&
          invitedOrReserved.includes(x.status)
      );
      if (registration != null) {
        errors.push({
          field: "email",
          message: {
            invited: `"${email}" is already invited`,
            registered: `"${email}" is already registered`,
            waiting: `"${email}" is already on the waitlist`,
            "invited-from-waitlist": `"${email}" is already on the waitlist`,
          }[registration.status],
        });
      }
    }

    return errors;
  };
}
