import { isBlank } from "@sempra-event-registration/common";

function nameAndId(value) {
  const { id, name } = value;
  return {
    id,
    name,
  };
}

export function registrationToFormDefaultValues(registration, event) {
  const {
    internalResource,
    email = "",
    firstName = "",
    lastName = "",
    phone = "",
    title = "",
    companyName = "",
    group: selectedGroup = {},
    subgroup: selectedSubgroup = {},
    meals: selectedMeals = [],
    workshops: selectedWorkshops = [],
    ...rest
  } = registration;

  const { meals: eventMeals = [], groups = [] } = event;

  const meals = eventMeals.map((meal) => {
    const selectedMeal = selectedMeals.find((x) => x.id === meal.id);
    const option =
      selectedMeal?.option?.id ||
      ((meal.options || []).length > 0 ? meal.options[0].id : undefined);
    return {
      id: meal.id,
      selected: selectedMeal != null,
      option,
    };
  });

  let firstOpenSubgroup = {};
  const selectedGroupNoSubgroups = groups.find((group) => {
    if (group.id === selectedGroup.id && !group.subgroups) return true;
  });
  const firstOpenGroup =
    groups.find((group) => {
      if (!group.subgroups) return true;
      // If selected group has no subgroups, we will default to empty string for subgroups
      // instead of finding first open subgroup
      firstOpenSubgroup = selectedGroupNoSubgroups
        ? ""
        : group?.subgroups?.find((subgroup) => {
            return subgroup.registrationCount < subgroup.capacity;
          });
      return firstOpenSubgroup?.id || "";
    }) || {};

  const group = selectedGroup?.id || firstOpenGroup?.id || "";
  const subgroup = selectedSubgroup?.id || firstOpenSubgroup?.id || "";

  const workshops = selectedWorkshops.map((x) => x.id);

  return {
    emailOrResource: internalResource || { email },
    firstName,
    lastName,
    phone,
    title,
    companyName,
    group,
    subgroup,
    meals,
    workshops,
    ...rest,
  };
}

export function formValuesToRegistration(values, event) {
  const {
    emailOrResource,
    firstName,
    lastName,
    phone,
    title,
    companyName,
    group: groupId,
    subgroup: subgroupId,
    meals = [],
    workshops = [],
    ...rest
  } = values;

  const email =
    typeof emailOrResource === "string"
      ? emailOrResource
      : emailOrResource.email;

  const selectedMeals = meals
    .filter((x) => x.selected)
    .map((x) => {
      const eventMeal = event.meals.find((y) => x.id === y.id);
      const option =
        x.option != null
          ? nameAndId(eventMeal.options.find((y) => y.id === x.option))
          : null;
      return {
        ...nameAndId(eventMeal),
        option,
      };
    });

  const selectedWorkshops = workshops.map((workshopId) =>
    event.workshops.find((x) => x.id === workshopId)
  );

  const registration = {
    firstName,
    lastName,
    email,
    emailNormalized: email.toUpperCase(),
    phone,
    title,
    companyName,
    meals: selectedMeals,
    workshops: selectedWorkshops,
    ...rest,
  };

  if (typeof emailOrResource !== "string" && emailOrResource.id != null) {
    const id = emailOrResource.id.toLowerCase();
    registration.internalUserId = id;
    registration.internalResource = {
      ...emailOrResource,
      id,
    };
  }

  if (!isBlank(groupId)) {
    const group = event.groups.find((x) => x.id === groupId);
    registration.group = nameAndId(group);
    if (!isBlank(subgroupId)) {
      const subgroup = group.subgroups.find((x) => x.id === subgroupId);
      registration.subgroup = nameAndId(subgroup);
    }
  }

  return registration;
}
