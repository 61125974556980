import React from "react";
import { Input } from "../../../../../../../shared/react-hook-form";
import { selectError } from "../../../../../../../shared/form/support";
import { requiredNotBlank } from "@sempra-event-registration/common";

function EventLocationCity({ errors, ...rest }) {
  return (
    <Input
      name="city"
      label="City"
      error={selectError(() => errors.city)}
      rules={{
        validate: requiredNotBlank("Required"),
      }}
      {...rest}
    />
  );
}

export default EventLocationCity;
