import React, { useState, createRef } from "react";
import { Modal, Button } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { scrollToFirstError } from "../../../../../../../shared/form";

function FormModal({
  heading,
  editing,
  defaultValues,
  disabled,
  onClose,
  onAccept,
  accept = (editing) => (editing ? "Save" : "Add"),
  children,
  registration,
  ...rest
}) {
  const [submitting, setSubmitting] = useState(false);
  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });
  const { trigger, getValues, formState } = form;
  const formFieldsRef = createRef();

  async function onAcceptButtonClick() {
    const valid = await trigger();
    if (valid) {
      onAccept(getValues(), { setSubmitting });
    } else {
      scrollToFirstError(formFieldsRef.current);
    }
  }

  return (
    <FormProvider {...form}>
      <Modal
        size="tiny"
        dimmer="blurring"
        centered={false}
        onClose={() => (submitting ? () => {} : onClose)}
        {...rest}
      >
        <Modal.Header>{heading}</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form ref={formFieldsRef}>{children}</Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <>
            {disabled || (
              <Button
                primary
                loading={submitting}
                disabled={editing && !formState.isDirty}
                onClick={onAcceptButtonClick}
              >
                {accept(editing)}
              </Button>
            )}
            <Button
              disabled={submitting}
              onClick={() => {
                onClose();
              }}
            >
              {disabled ? "Close" : "Cancel"}
            </Button>
          </>
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
}

export default FormModal;
