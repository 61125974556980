import React from "react";
import NumberFormat from "react-number-format";
import classes from "./CapacityFraction.module.css";

function CapacityFraction({ top = 0, bottom = 0 }) {
  // Type correct data
  top = parseInt(top);
  bottom = parseInt(bottom);

  // Don't display invalid fractions
  if (
    top == null ||
    isNaN(top) ||
    bottom == null ||
    isNaN(bottom) ||
    bottom === 0
  ) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <NumberFormat value={top} displayType={"text"} thousandSeparator={true} />
      <span className={classes.line}>/</span>
      <NumberFormat
        value={bottom}
        displayType={"text"}
        thousandSeparator={true}
      />
    </div>
  );
}

export default CapacityFraction;
