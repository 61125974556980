import React from "react";
import { Button } from "semantic-ui-react";

function SaveButton({ disabled, className, loading, onClick }) {
  return (
    <Button
      type="button"
      primary
      {...{ className, loading, onClick, disabled }}
    >
      Save
    </Button>
  );
}

export default SaveButton;
