import React from "react";
import { Input } from "../../../../../../../shared/react-hook-form";
import { selectError } from "../../../../../../../shared/form/support";

function EventLocationZip({ errors, ...rest }) {
  return (
    <Input
      name="zipCode"
      label="Zip"
      error={selectError(() => errors.zipCode)}
      rules={{
        required: "Required",
      }}
      type="zipCode"
      {...rest}
    />
  );
}

export default EventLocationZip;
