export function scrollToFirstError(from = document) {
  try {
    from.querySelector(".field.error").scrollIntoView({ behavior: "smooth" });
  } catch (error) {
    console.debug(
      "developer error: missing error message where expected",
      error
    );
  }
}
