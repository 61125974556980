function editOption({ adminUser, onClick }) {
  return {
    key: "edit",
    text: "Edit",
    onClick: () => onClick(adminUser),
  };
}

function removeOption({ adminUser, onClick }) {
  return {
    key: "remove",
    text: "Remove",
    onClick: () => onClick(adminUser),
  };
}

export function adminMenuOptionsFactory({
  onEditOptionClick,
  onRemoveOptionClick,
}) {
  return (adminUser) => {
    return [
      editOption({ adminUser, onClick: onEditOptionClick }),
      removeOption({ adminUser, onClick: onRemoveOptionClick }),
    ];
  };
}
