/**
 * Get a map of the event's group id to group name.
 *
 * @example
 * // example output:
 * {
 *   "8ea754ee-6e09-4341-9044-c1d3775d0697" : "group 1",
 *   "d3b59cba-c888-443b-b3ff-28da6ce552df" : "a group with no subgroups",
 * }
 * @param event The event containing the associated groups.
 * @returns {Object} a map where the key is the group id and the value is the group name.
 */
import { comparing, stringComparator } from "@sempra-event-registration/common";

function getGroupIdToNameMap(event) {
  return event.groups.reduce((accumulator, group) => {
    accumulator[group.id] = group.name;

    return accumulator;
  }, {});
}

/**
 * Get a map of the event's sub group id to the sub group name and capacity.
 * <p>
 * <strong>NOTES:</strong>
 * </p>
 * <ul>
 *   <li>it is possible to have a group without any sub groups</li>
 *   <li>only sub groups have capacity</li>
 * </ul>
 *
 * @example
 * // example output:
 * {
 *   "8ea754ee-6e09-4341-9044-c1d3775d0697" : { name: "subgroup 1", capacity: 5 },
 *   "d3b59cba-c888-443b-b3ff-28da6ce552df" : { name: "subgroup 2", capacity: 10 },
 * }
 * @param event The event containing the associated groups.
 * @returns {Object} a map where the key is the group id and the value is the group name.
 */
function getSubGroupIdToNameAndCapacityMap(event) {
  return event.groups
    .flatMap((group) => group.subgroups)
    .concat()
    .filter((subgroup) => subgroup)
    .reduce((accumulator, subGroup) => {
      accumulator[subGroup.id] = {
        name: subGroup.name,
        capacity: subGroup.capacity,
      };

      return accumulator;
    }, {});
}

/**
 * "Flatten out" the registrant to only what the group report needs.  For each registrant, the report is interested in:
 * <ul>
 *   <li>What group the registrant is assigned to</li>
 *   <li>What sub group the registrant is assigned to (if any)</li>
 *   <li>The sub group's capacity (which will be used for displaying if a sub group is over capacity)</li>
 * </ul>
 * @example
 * // example of a mapped registrant who is assigned to a group and sub group:
 * { groupName: "group 1", subGroupName: "sub group 1", subGroupCapacity: 5 }
 *
 * // example of a mapped registrant who is not assigned to a group and sub group:
 * { groupName: "group 1", subGroupName: "", subGroupCapacity: 0 }
 *
 * @param registrant {Object} The registrant who is assigned to a group (and possibly a subgroup)
 * @param groupToNameMap {Object} A map to look up a group name by its id
 * @param subGroupToNameMap {Object} A map to look up a sub group name by its id
 * @returns {{subGroupName: (*|string), groupName: *, subGroupCapacity: (number)}} An object containing the registrant's
 * group, sub group and sub group's capacity
 */
function mapRegistrantToReportRow(
  registrant,
  groupToNameMap,
  subGroupToNameMap
) {
  const subGroup = subGroupToNameMap[registrant.subgroup?.id];

  return {
    groupName: groupToNameMap[registrant.group.id],
    subGroupName: subGroup?.name || "",
    subGroupCapacity: Number(subGroup?.capacity) || 0,
  };
}

/**
 * Calculate the number of registrants that have been assigned to a group (and possibly subgroup).
 *
 * @example
 * // example output
 * [{
 *   groupName: "group 1",
 *   subGroupName: "group 1 subgroup 1",
 *   count: 2,
 *   subGroupCapacity: "5",
 * },
 * {
 *   groupName: "group with no subgroups"
 *   subGroupName: ""
 *   count: 1
 *   subGroupCapacity: 0
 * }]
 *
 * @param registrants {Array} A collection of registrants that are registered for an event
 * @param event {Object} The event being reported on
 * @returns {*[]|*} An array of report records indicating how many registered users are in each group/subgroup.
 */
export default function groupRegistrantsByGroupAndSubgroup(registrants, event) {
  const groupIdToNameMap = getGroupIdToNameMap(event);
  const subGroupIdToNameAndCapacityMap = getSubGroupIdToNameAndCapacityMap(
    event
  );

  // This report is only interested in tabulating registrants who are assigned to groups and/or subgroups.  If there
  // are no registrants w/group membership, exit.
  const registrantsAssignedToGroups = registrants.filter(
    (registrant) => registrant.group != null
  );

  if (registrantsAssignedToGroups.length === 0) {
    return [];
  }

  return registrantsAssignedToGroups
    .map((registrant) =>
      mapRegistrantToReportRow(
        registrant,
        groupIdToNameMap,
        subGroupIdToNameAndCapacityMap
      )
    )
    .reduce((accumulator, groupReportRecord) => {
      const existingRow = accumulator.find(
        (reportRow) =>
          reportRow.subGroupName === groupReportRecord.subGroupName &&
          reportRow.groupName === groupReportRecord.groupName
      );

      existingRow
        ? existingRow.count++
        : accumulator.push({ ...groupReportRecord, count: 1 });

      return accumulator;
    }, [])
    .sort(comparing(stringComparator, (x) => x.groupName));
}
