import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Search } from "semantic-ui-react";
import useGooglePlacesService from "./helpers/useGooglePlacesService";
import googlePlaceToLocation from "./helpers/googlePlaceToLocation";
import styles from "./GooglePlacesTypeahead.module.css";

const USE_INPUT_TEXT = "useInputText";

function GooglePlacesTypeahead({
  placeholder,
  value,
  onChange,
  onBlur,
  onSelect,
  ...rest
}) {
  const {
    authenticationError,
    getPlaceDetails,
    getPlacePredictions,
    OK,
  } = useGooglePlacesService();

  const [results, setResults] = useState([]);

  const getOptions = useCallback((predictions, inputValue) => {
    const useTextOption = [
      {
        childKey: USE_INPUT_TEXT,
        title: "Use your typed text:",
        description: inputValue,
        placeid: USE_INPUT_TEXT,
      },
    ];

    return predictions
      .map((prediction, index) => {
        return {
          childKey: index,
          title: prediction.structured_formatting.main_text,
          description: prediction.structured_formatting.secondary_text,
          placeid: prediction.place_id,
        };
      })
      .concat(useTextOption);
  }, []);

  const onError = useCallback((status, response) => {
    if (status !== "ZERO_RESULTS") {
      console.error("ERROR - STATUS : ", status);
      console.error("ERROR - RESPONSE : ", response);
    }
  }, []);

  const onSearchChange = useCallback(
    (e, input) => {
      onChange(input.value);

      const callback = (response, status) => {
        if (status === OK || status === "ZERO_RESULTS") {
          return setResults(getOptions(response || [], input.value));
        }
        return onError(status, response);
      };

      input.value.length === 0
        ? setResults([])
        : getPlacePredictions(input.value, callback);
    },
    [getPlacePredictions, onChange, getOptions, OK, onError]
  );

  const onResultSelect = (e, input) => {
    input.result.placeid === USE_INPUT_TEXT
      ? onChange(input.value)
      : getPlaceDetails(
          input.result.placeid,
          handleReturnPlaceToAddresses(input.result.placeid)
        );
  };

  const handleReturnPlaceToAddresses = (placeId) => (place, status) => {
    const address = googlePlaceToLocation(place, placeId);
    onChange(address.addressLine1);
    onSelect(address);
  };

  return (
    <Search
      className={styles.typeahead}
      icon={null}
      fluid
      results={!authenticationError ? results : null}
      value={value || ""}
      {...{
        ...rest,
        placeholder,
        onBlur,
        onResultSelect,
        onSearchChange,
      }}
    />
  );
}

GooglePlacesTypeahead.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onGoogleAddressSelect: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default GooglePlacesTypeahead;
