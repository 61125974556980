import { firebaseReference } from "@sempra-event-registration/common";
import cloneDeepWith from "lodash.clonedeepwith";

/**
 * Prepare the internal user date for persisting it to Firestore.
 * <p>
 *   Firestore throws errors for <code>field: undefined</code>, so any <code>undefined</code> fields are set to
 *   <code>null</code>.  Additionally, any <code>string</code>s are trimmed to remove whitespace.
 * </p>
 *
 * @param internalUser {Object} the <code>internalUser</code> to map/sanitize for persistence to Firestore.
 * @returns {*} An object ready for persistence to Firestore.
 */
function firebaseSafe(internalUser) {
  return cloneDeepWith(internalUser, (value) => {
    if (value == null) {
      return null;
    }
    if (typeof value === "string") {
      return value.trim();
    }
  });
}

/**
 * "Upsert" a user document to the <code>internal_users</code> collection:
 * <ul>
 *   <li>If a document representing the user already exists in the <code>internal_users</code> collection, update it with the incoming data
 *   <li>Otherwise, create a new record in the <code>internal_users</code> collection
 * </ul>
 *
 * @param user {Object} the user performing the insert/update operation (<em>not the user being upserted</em>)
 * @param internalUserToSave {Object} the <code>internal_users</code>
 */
export function saveInternalUser(user, internalUserToSave) {
  const { id } = internalUserToSave;
  const documentData = firebaseSafe({
    ...internalUserToSave,
    updatedAt: new Date(),
    updatedBy: user.id,
  });

  return firebaseReference
    .firestore()
    .doc(`internal_users/${id}`)
    .set(
      {
        ...documentData,
      },
      { merge: true }
    )
    .then(() => ({
      ...documentData,
      id,
    }));
}
