import React from "react";
import classes from "./EditorActionButton.module.css";

const EditorActionButton = ({ onClick, disabled, label, ...rest }) => {
  return (
    <button
      className={classes.editorActionButton}
      type="button"
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {label}
    </button>
  );
};

export default EditorActionButton;
