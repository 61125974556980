import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import classes from "./EventFeatureImageUploader.module.css";
import { Button } from "semantic-ui-react";
import {
  CroppedImage,
  DEFAULT_IMAGE_URL,
} from "@sempra-event-registration/common";

// TODO consider renaming to "ImageChooser" as we now have EventFeatureImage component
// TODO make this more generic and put it in shared/ui/component
function EventFeatureImageUploader({ value, onChange, disabled }) {
  const [objectUrl, setObjectUrl] = useState();
  const imageUrl = objectUrl || value;

  function setFile(file) {
    if (objectUrl != null) URL.revokeObjectURL(objectUrl);

    setObjectUrl(file ? URL.createObjectURL(file) : undefined);
    onChange(file);
  }

  const { getInputProps, open } = useDropzone({
    // if the accepted content types change, also update the Firebase storage.rules to match
    accept: "image/jpeg, image/jpg, image/webp, image/png",
    onDrop: (selectedFiles) => {
      if (selectedFiles.length > 1) {
        alert("You can only upload one file.");
        return;
      }
      setFile(selectedFiles[0]);
    },
  });

  return (
    <div className={`${classes.container} ${disabled ? classes.disabled : ""}`}>
      <CroppedImage url={imageUrl} aspectRatio={3}>
        <div className={classes.controls}>
          {/* this input is needed here to get the change button to open the file browser */}
          <input {...getInputProps()} />
          {!disabled && (
            <>
              <Button
                inverted
                type="button"
                onClick={() => {
                  open();
                }}
              >
                Change
              </Button>
              <Button
                inverted
                type="button"
                onClick={() => {
                  setObjectUrl(DEFAULT_IMAGE_URL);
                  onChange(undefined);
                }}
              >
                Use Default
              </Button>
            </>
          )}
        </div>
      </CroppedImage>
    </div>
  );
}

export default EventFeatureImageUploader;
