import React from "react";
import { Modal, Button } from "semantic-ui-react";

function CancelEventModal({ event, onAccept, onClose, ...rest }) {
  const { name } = event;
  return (
    <Modal
      size="tiny"
      dimmer="blurring"
      centered={false}
      onClose={onClose}
      {...rest}
    >
      <Modal.Header>Cancel</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Cancelling this event will immediately and irreversibly make it
            unavailable for registration.
          </p>
          <p>
            Are you sure you want to cancel <strong>{name}</strong>?
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            onAccept();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Dismiss
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CancelEventModal;
