import React from "react";
import EventDescription from "../../field/EventDescription/EventDescription";
import EventName from "../../field/EventName/EventName";
import EventFeatureImage from "../../field/EventFeatureImage/EventFeatureImage";
import EventOptions from "../../field/EventOptions/EventOptions";
import EventCompanyName from "../../field/EventCompanyName/EventCompanyName";
import EventAudience from "../../field/EventAudience/EventAudience";
import classes from "./EventDetailsSection.module.css";
import EventSummary from "../../field/EventSummary/EventSummary";
import { Input } from "../../../../../../../shared/react-hook-form";
import { useFormContext } from "react-hook-form";

function EventDetailsSection({
  fieldProps,
  event,
  editing,
  eventId,
  description,
  setDescription,
  triedToPublish,
  ...rest
}) {
  const { watch } = useFormContext();
  const { type, audience } = event;

  return (
    <div {...rest}>
      <div className={classes.grid}>
        <div>
          <Input
            name="updatedAt"
            type="hidden"
            defaultValue={""}
            {...fieldProps}
          />
          <EventName {...fieldProps} />
          {type === "internal" && (
            <>
              <EventCompanyName {...fieldProps} />
              <EventAudience {...{ ...fieldProps, audience, watch }} />
            </>
          )}
          <EventOptions type={type} {...fieldProps} />
        </div>

        <EventFeatureImage {...fieldProps} />
      </div>
      <EventSummary {...fieldProps} />
      <EventDescription
        {...{ ...fieldProps, description, setDescription, triedToPublish }}
      />
    </div>
  );
}

export default EventDetailsSection;
