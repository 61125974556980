import {
  firebaseReference,
  firestoreCollectionQuery,
  map,
  compose,
} from "@sempra-event-registration/common";

function eventDocumentToEvent(document) {
  const data = document.data();
  return {
    id: document.id,
    audience: data.audience,
    name: data.name,
    status: data.status,
    startsAt: data.startsAt?.toDate(),
    endsAt: data.endsAt?.toDate(),
    capacity: data.location?.capacity,
    createdBy: data.createdBy,
    createdAt: data.createdAt?.toDate(),
    type: data.type,
    reservationCount: data.reservationCount,
  };
}

function userDocumentToUser(document) {
  const data = document.data();
  return {
    id: document.id,
    firstName: data.firstName,
    lastName: data.lastName,
  };
}

const useEventsInternal = firestoreCollectionQuery((user) =>
  firebaseReference
    .firestore()
    .collection("events")
    .where(
      "type",
      "in",
      user.permissions
        .filter((permission) => permission.endsWith("_event_read"))
        .map((permission) => permission.replace(/_event_read$/g, ""))
    )
)
  .map((snapshot) => snapshot.docs.map(eventDocumentToEvent))
  .build();

const useInternalUsers = firestoreCollectionQuery(() =>
  firebaseReference.firestore().collection("internal_users")
)
  .map((snapshot) => snapshot.docs.map(userDocumentToUser))
  .build();

export const useEvents = function (user) {
  return map(
    compose(useEventsInternal(user), useInternalUsers()),
    ([events, users]) =>
      events.map((event) => ({
        ...event,
        createdBy: users.find(({ id }) => id === event.createdBy) || {
          id: event.createdBy,
        },
      }))
  );
};
