import React from "react";
import EventDatePicker from "../../field/EventDate/EventDate";
import EventRegistrationDeadline from "../../field/EventRegistrationDeadline/EventRegistrationDeadline";
import EventStartTime from "../../field/EventStartTime/EventStartTime";
import EventEndTime from "../../field/EventEndTimePicker/EventEndTime";
import FieldContainer from "../../component/FieldContainer/FieldContainer";
import styles from "./EventDateAndTimeSection.module.css";

function EventDateAndTimeSection({ fieldProps }) {
  return (
    <FieldContainer>
      <EventDatePicker {...fieldProps} />
      <div className={styles.grid}>
        <div>
          <EventStartTime {...fieldProps} />
        </div>
        <div>
          <EventEndTime {...fieldProps} />
        </div>
      </div>
      <EventRegistrationDeadline {...fieldProps} />
    </FieldContainer>
  );
}

export default EventDateAndTimeSection;
