import {
  firebaseReference,
  firestoreCollectionQuery,
} from "@sempra-event-registration/common";

export const useEventRegistrations = firestoreCollectionQuery((eventId) =>
  firebaseReference.firestore().collection(`events/${eventId}/registrants`)
)
  .condition((eventId) => eventId != null)
  .map((snapshot) =>
    snapshot.docs.map((document) => ({
      ...document.data(),
      id: document.id,
    }))
  )
  .build();
