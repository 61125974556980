import { selectError } from "../../../../../../../../../shared/form/support";
import React from "react";
import { email, requiredNotBlank } from "@sempra-event-registration/common";
import { Input as InputComponent } from "../../../../../../../../../shared/form";
import { Controller } from "react-hook-form";

function field(selector = (value) => value, validator) {
  return (value) => validator(selector(value));
}

function RegistrationEmail({
  control,
  disabled,
  errors,
  emailAvailabilityValidator,
  emailInAudienceValidator,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name="emailOrResource"
      rules={{
        validate: {
          required: field((x) => x.email, requiredNotBlank()),
          email: field((x) => x.email, email()),
          emailAvailability: field((x) => x.email, emailAvailabilityValidator),
          emailInAudience: field((x) => x.email, emailInAudienceValidator),
        },
      }}
      render={({ value, onChange, ...props }) => (
        <InputComponent
          label="Email"
          type="text"
          disabled={disabled}
          error={selectError(() => errors.emailOrResource)}
          value={value?.email || ""}
          onChange={(value) => {
            onChange({ email: value || "" });
          }}
          {...props}
          {...rest}
        />
      )}
    />
  );
}

export default RegistrationEmail;
