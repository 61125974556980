import { isBefore } from "date-fns";
import { timeOf } from "../date";

/**
 * Verify that the start time occurs before the end time.
 *
 * @param {Date} startTime The date which should occur first
 * @param {Date} endTime The date which should occur second
 * @param {string} message The error message that should be displayed if the validation fails
 * @returns {string|null} null if the validation is successful; otherwise the error message
 */
export function timeIsBefore(
  startTime,
  endTime,
  message = "Start time must occur before end time"
) {
  // If either the startTime or endTime are null/undefined, there's nothing to compare (either the user has blanked out
  // or not provided input for one of the fields).
  if (startTime == null || endTime == null) {
    return null;
  }

  return isBefore(timeOf(endTime), timeOf(startTime)) ? message : null;
}
