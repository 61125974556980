import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  emptyResource,
  errorResource,
  loadedResource,
  loadingResource,
} from "../resource";

export function withBreadcrumbs(WrappedComponent, options = {}) {
  function WithBreadcrumbs(props) {
    const { match } = props;
    const breadcrumbs = useBreadcrumbs(match, options);
    if (breadcrumbs.status !== "loaded") {
      return <React.Fragment />;
    }
    return <WrappedComponent {...props} breadcrumbs={breadcrumbs.value} />;
  }
  return withRouter(WithBreadcrumbs);
}

function useBreadcrumbs(match, options) {
  const [breadcrumbs, setBreadcrumbs] = useState(emptyResource());
  useEffect(() => {
    // Fixes error regarding updating state on unmounted components
    let mounted = true;

    const { resolvers = {}, defaultRoute, documentTitle } = options;

    setBreadcrumbs(loadingResource());
    resolveBreadcrumbs(match, resolvers, defaultRoute)
      .then((value) => {
        if (typeof documentTitle === "function") {
          document.title = documentTitle(value);
        }
        mounted && setBreadcrumbs(loadedResource(value));
      })
      .catch((error) => {
        mounted && setBreadcrumbs(errorResource(error));
      });

    return () => {
      mounted = false;
    };
  }, [match, options]);

  return breadcrumbs;
}

async function resolveBreadcrumbName(pathPart, pathParameter, resolver) {
  if (resolver == null) {
    return pathParameter;
  }
  if (typeof resolver !== "function") {
    return resolver;
  }
  const resolved = resolver(pathParameter);
  if (typeof resolved.then === "function") {
    return await resolved;
  }
  return resolved;
}

async function resolveBreadcrumbs(match, resolvers, defaultRoute) {
  const pathParts = match.path.split("/");
  const pathParameters = match.url.split("/");
  const breadcrumbs = [];
  const startIndex = match.url.startsWith(defaultRoute) ? 1 : 0;
  for (let i = startIndex; i < pathParts.length; i++) {
    const pathPart = pathParts[i];
    const pathParameter = pathParameters[i];
    const pathAtIndex = pathParameters.slice(0, i + 1);
    const url =
      pathAtIndex.length > 1 ? pathParameters.slice(0, i + 1).join("/") : "/";
    const name = await resolveBreadcrumbName(
      pathPart,
      pathParameter,
      resolvers[pathPart]
    );
    breadcrumbs.push({ url, name });
  }
  return breadcrumbs;
}
