import {
  firebaseReference,
  firestoreCollectionQuery,
} from "@sempra-event-registration/common";

/**
 * Map the <code>internal_users</code> document fetched from Firestore into an <code>internalUser</code> model.
 *
 * @param document {*} A document from the <code>internal_users</code> collection.
 * @returns {{[p: string]: *}} An <code>internalUser</code> model.
 */
function mapDocumentToInternalUser(document) {
  const data = document.data();
  return {
    id: document.id,
    name: `${data.firstName || ""} ${data.lastName || ""}`.trim(),
    ...data,
  };
}
/**
 * Get the collection of roles a user supervises.
 * <p>
 *   In this case, "supervises" means the "supervisor"-level user has one of the <code>_admin_write</code>
 *   <strong><em>permission</em></strong> assigned to them.  There is a corresponding <code>_admin</code>
 *   <strong>role</strong>; anyone with that role is considered "supervised".  For example, a user with the
 *   <code>internal_user_admin_write</code> <strong><em>permission</em></strong> "supervises" any user with the
 *   <code>internal_user_admin</code> <strong>role</strong>.
 * </p>
 * <p>
 *   Refer to the <code>userClaims rolesToPermissions</code> map for details on which supervisor roles supervise.
 * </p>
 *
 * @param userPermissions {string[]} The collection of permissions (a.k.a. custom claims) from the Firebase user.
 * @returns {string[]} An array of roles the user supervises.
 */
function getSupervisedRoles(userPermissions) {
  return userPermissions.reduce((acc, permission) => {
    if (permission.endsWith("_admin_write")) {
      return acc.concat(permission.replace("_admin_write", "_admin"));
    }

    return acc;
  }, []);
}

/**
 * Get all <strong>_event_admin</strong> users from the <code>internal_users</code> collection.  The results are limited
 * to the <strong>_admin</strong> roles the user can supervise.
 */
export const useInternalUsersWithAdminRoles = firestoreCollectionQuery(
  (userPermissions) => {
    const supervisedRoles = getSupervisedRoles(userPermissions);

    return firebaseReference
      .firestore()
      .collection("internal_users")
      .where("roles", "array-contains-any", supervisedRoles);
  }
)
  .map((snapshot) => snapshot.docs.map(mapDocumentToInternalUser))
  .mapError((error) => {
    console.error("error", error);
    return error;
  })
  .build();
