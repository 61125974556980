import React from "react";
import { Button } from "semantic-ui-react";
import styles from "../RichTextEditor.module.css";

function ListTypeButtonGroup({ editorState, onButtonClick }) {
  const listTypes = [
    { label: "Bulleted List", style: "unordered-list-item", icon: "list ul" },
    { label: "Numbered List", style: "ordered-list-item", icon: "list ol" },
  ];

  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const buttons = listTypes.map((listType) => {
    return {
      key: listType.icon,
      icon: listType.icon,
      active: blockType === listType.style,
      onClick: (e) => onButtonClick(e, listType.style),
      className: styles.noBorder,
    };
  });

  return <Button.Group basic buttons={buttons} className={styles.noBorder} />;
}

export default ListTypeButtonGroup;
