import { eventTypeNamesByType } from "@sempra-event-registration/common";

const eventTypeOptions = ["internal", "sdge_employee", "sdge_external"].map(
  (type) => ({
    value: type,
    label: `${eventTypeNamesByType[type]} Event`,
    writable: (permissions) => permissions.includes(`${type}_event_write`),
  })
);

export function permissionsToWritableEventTypeOptions(permissions) {
  return eventTypeOptions.filter(({ writable }) => writable(permissions));
}
