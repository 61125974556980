import React, { useContext, useState } from "react";
import { useSearchRestoringHistory } from "../../../../../shared/router";
import { IconButton, Breadcrumbs } from "../../../../../shared/ui";
import {
  compose,
  Error,
  registrationStatus,
} from "@sempra-event-registration/common";
import { Segment } from "semantic-ui-react";
import ReportsMenu from "./component/ReportsMenu/ReportsMenu";
import { EventContext } from "../../context/EventContext";
import { getReportOptions } from "../../support/eventReportPageReportMenuOptions";
import ReportDisplay from "./component/ReportDisplay/ReportDisplay";
import Loader from "../../../../../shared/ui/component/Loader/Loader";
import { useEventRegistrations } from "../../hook/useEventRegistrations";
import styles from "./EventReportsPage.module.css";

function EventReportsPage() {
  const history = useSearchRestoringHistory();
  const eventResource = useContext(EventContext);
  const registrationsResource = useEventRegistrations(eventResource.value?.id);
  const [selectedReportOption, setSelectedReportOption] = useState();
  const resources = compose(eventResource, registrationsResource);

  if (resources.status === "loading" || resources.status === "empty") {
    return <Loader />;
  }

  if (resources.status === "error") {
    return <Error message={eventResource.error.message} />;
  }

  const [event, registrations] = resources.value;
  const registeredUsers = registrations.filter(
    (registrant) => registrant.status === registrationStatus.registered
  );
  const reportOptions = getReportOptions(event, registeredUsers);

  const reportOption =
    selectedReportOption != null ? selectedReportOption : reportOptions[0];

  const report = {
    title: reportOption.title,
    columns: reportOption.columns,
    rows: reportOption.rows(),
    fileName: reportOption.key,
  };

  function onClose() {
    history.push(`/events/${event.id}`);
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topGrid}>
        <Breadcrumbs />
        <IconButton type="button" icon="close" size="huge" onClick={onClose} />
      </div>
      <div className={styles.bottomGrid}>
        <ReportsMenu
          options={reportOptions}
          activeOption={reportOption}
          onOptionClick={setSelectedReportOption}
        />
        <Segment>
          <ReportDisplay report={report} />
        </Segment>
      </div>
    </div>
  );
}

export default EventReportsPage;
