import React from "react";
import { format } from "date-fns-tz";
import {
  dateComparator,
  eventTimeStatuses,
  eventTimeStatusNameByValue,
  eventToEventTimeStatus,
  isBlank,
  nullsFirst,
  ordering,
} from "@sempra-event-registration/common";
import classes from "./EventTable.module.css";
import { SemanticDataTable } from "../../../../../shared/ui";
import EventTimeStatusBadge from "../EventTimeStatusBadge/EventTimeStatusBadge";

function EventTable({
  events,
  onRowClick,
  rowOptions,
  showTypeColumn = false,
  ...rest
}) {
  const columns = [
    {
      id: "startsAt",
      title: "Date",
      sortable: true,
      dataType: "date",
      value: (x) => x.startsAt,
      cellProps: () => ({ collapsing: true }),
      comparator: nullsFirst(dateComparator),
      format: (value) =>
        format(value, "MMM dd, yyyy", {
          timeZone: "America/Los_Angeles",
        }),
    },
    {
      id: "name",
      title: "Event",
      sortable: true,
      value: (x) => x.name,
      format: (value) => <div className={classes.name}>{value}</div>,
    },
    ...(showTypeColumn
      ? [
          {
            id: "audience",
            title: "Audience",
            sortable: true,
            cellProps: () => ({ collapsing: true }),
            csvValue: (x) => {
              return [
                x.audience?.userType?.external ? "External" : null,
                x.audience?.userType?.internal ? "Internal" : null,
              ]
                .filter((x) => x != null)
                .join(", ");
            },
            value: (x) => {
              return [
                x.audience?.userType?.external ? "External" : null,
                x.audience?.userType?.internal ? "Internal" : null,
              ]
                .filter((x) => x != null)
                .join(", ");
            },
            format: (audience) => audience,
          },
        ]
      : []),
    {
      id: "createdBy",
      title: "Author",
      sortable: true,
      cellProps: () => ({ collapsing: true }),
      value: (x) =>
        !isBlank(x.createdBy.firstName)
          ? `${x.createdBy.firstName} ${x.createdBy.lastName}`.trim()
          : x.createdBy.email || x.createdBy.id,
      csvValue: (x) =>
        !isBlank(x.createdBy.firstName)
          ? `${x.createdBy.firstName} ${x.createdBy.lastName}`.trim()
          : x.createdBy.email || x.createdBy.id,
    },
    {
      id: "reservationCount",
      title: "Registrants",
      sortable: true,
      dataType: "number",
      cellProps: () => ({ collapsing: true }),
      value: (x) =>
        x.reservationCount == null || isNaN(parseInt(x.reservationCount))
          ? null
          : parseInt(x.reservationCount),
      textAlign: "right",
    },
    {
      id: "capacity",
      title: "Capacity",
      sortable: true,
      dataType: "number",
      cellProps: () => ({ collapsing: true }),
      value: (x) =>
        x.capacity == null || isNaN(parseInt(x.capacity))
          ? null
          : parseInt(x.capacity),
      textAlign: "right",
    },
    {
      id: "status",
      title: "Status",
      sortable: true,
      cellProps: () => ({ collapsing: true }),
      value: (event) => eventToEventTimeStatus(event),
      searchableValue: (value) => eventTimeStatusNameByValue[value],
      comparator: ordering(eventTimeStatuses),
      format: (value) => <EventTimeStatusBadge status={value} />,
    },
  ];

  return (
    <SemanticDataTable
      {...rest}
      singleLine
      striped
      stickyControls
      searchEnabled
      stickyControlsOffset={64}
      pageSize={30}
      className={classes.table}
      columns={columns}
      rows={events}
      rowOptions={rowOptions}
      rowKeyProvider={(event) => event.id}
      onRowClick={(e, event) => onRowClick(event)}
    />
  );
}

export default EventTable;
