import { useState, useEffect } from "react";
import { firebaseReference } from "@sempra-event-registration/common";
import { convertFromRaw, EditorState } from "draft-js";

const useDefaultMessages = () => {
  const [defaultMessages, setDefaultMessages] = useState({});

  useEffect(() => {
    const unsubscribe = firebaseReference
      .firestore()
      .collection("default_messages")
      .onSnapshot((snapshot) => {
        const messages = {};
        for (const doc of snapshot.docs) {
          const data = doc.data();
          messages[doc.id] = {
            id: doc.id,
            label: data.label,
            message: EditorState.createWithContent(
              convertFromRaw(data.message)
            ),
          };
        }
        setDefaultMessages(messages);
      });
    return unsubscribe;
  }, []);
  return defaultMessages;
};

export default useDefaultMessages;
