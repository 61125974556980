import React from "react";
import { Table } from "semantic-ui-react";

function TableHeaderRow({ columns, setSort, defaultSort, sort }) {
  const handleSortRowClick = ({ id }) => () => {
    const columnSort = sort.find(([columnId]) => columnId === id);
    const [columnId, sortDirection = "ascending"] = columnSort || [];

    // Third click on same column -( set default sort )
    if (sortDirection === "descending" && columnId === id)
      return setSort(defaultSort);

    // Set direction depending on first or second click
    const direction = columnId !== id ? "ascending" : "descending";

    setSort([[id, direction]]);
  };

  return (
    <Table.Header>
      <Table.Row>
        {columns.map((column) => {
          // Determines the display direction of the sorted column
          const columnSort = sort.find(([columnId]) => columnId === column.id);
          const columnSortDirection =
            columnSort != null ? columnSort[1] || "ascending" : null;

          // Determines the column header click behavior
          const onClick = column.sortable ? handleSortRowClick(column) : null;

          return (
            <Table.HeaderCell
              key={column.id}
              sorted={columnSortDirection}
              onClick={onClick}
              className={`${column.id} ${column.className || ""} ${
                column.headClassName || ""
              }`}
            >
              {column.title}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
}

export default TableHeaderRow;
